import React, { useEffect, useState } from "react";
import Select from "react-select";
import CheckBox from "../../../components/UI/Checkbox/CheckBox";
import { SeasonPassFieldLabel } from "../../SeasonPass/SeasonPassFieldLabel";
import moment from "moment";

import "../OpenSell.sass";
import { days, month, paymentMethodOptions, years } from "../../SeasonPass/constants";
import { isValidEmail } from "../../../helpers/validators";
import { getCurrentLanguageTexts } from "../../../constants/seasonPassPersonalInfoLangs";
import { SelectTickets } from "./SelectTickets";

export const OfferSteps = ({
  tickets,
  coupon,
  pricing,
  totalFee,
  loading,
  hasSeats,
  requiredFields,
  fields,
  checkboxeFields,
  transactionData,
  eventData,
  handleChange,
  onObjectSelected,
  onObjectDeselected,
  setCouponHandler,
  showTermsAndConditionsModal,
  handleChangeCheckbox,
}) => {
  const [pickerData, setPickerData] = useState({
    year: null,
    month: null,
    days: null,
  });

  const txt = getCurrentLanguageTexts();

  const genders = [
    { label: txt.sellPageFieldsTxt["Male"], value: "Male" },
    { label: txt.sellPageFieldsTxt["Female"], value: "Female" },
  ];

  const checkIsRequired = (key) => {
    return requiredFields.some((name) => name === key) && !fields[key];
  };

  useEffect(() => {
    const isNoEmpty = Object.keys(pickerData).every((key) => !!pickerData[key]);

    console.log(fields);
    

    if (!isNoEmpty && fields.birthday) {
      setPickerData({
        year: moment(fields.birthday).year(),
        month: moment(fields.birthday).month(),
        days: +moment(fields.birthday).format("D"),
      });
    }
  }, [fields.birthday]);

  const handleChangeDate = (type, date) => {
    let newPickerData = { ...pickerData, [type]: date };

    setPickerData(newPickerData);

    const isNoEmpty = Object.keys(newPickerData).every(
      (key) => typeof newPickerData[key] === "number"
    );

    if (isNoEmpty) {
      const birthday = moment()
        .year(newPickerData.year)
        .month(newPickerData.month)
        .set("D", newPickerData.days)
        .toDate();

      handleChange("birthday", birthday);
    }
  };

  return (
    <div className="partizan-open-selling__offerWrapper">
      <div className="partizan-open-selling__offer-left-block">
        <SeasonPassFieldLabel
          label={txt.sellPageFieldsTxt["Birth Date"]}
          shouldWrapInLabel={false}
          isRequired={checkIsRequired("birthday")}
          className="partizan-open-selling__fields-label"
        >
          <div className="partizan-open-selling__picker-wrapper">
            <Select
              options={years}
              placeholder={txt.sellPageFieldsTxt["Year"]}
              value={years.find(({ value }) => pickerData.year === value)}
              onChange={(value) => handleChangeDate("year", value.value)}
              classNamePrefix="custom-select"
              className="partizan-open-selling__select"
            />
            <Select
              options={month}
              placeholder={txt.sellPageFieldsTxt["Month"]}
              value={month.find(({ value }) => pickerData.month === value)}
              onChange={(value) => handleChangeDate("month", value.value)}
              classNamePrefix="custom-select"
              className="partizan-open-selling__select"
            />
            <Select
              options={days}
              placeholder={txt.sellPageFieldsTxt["Day"]}
              value={days.find(({ value }) => pickerData.days === value)}
              onChange={(value) => handleChangeDate("days", value.value)}
              classNamePrefix="custom-select"
              className="partizan-open-selling__select partizan-open-selling__select--day"
            />
          </div>
        </SeasonPassFieldLabel>

        <SeasonPassFieldLabel
          label={txt.sellPageFieldsTxt["Gender"]}
          isRequired={checkIsRequired("gender")}
          className="partizan-open-selling__fields-label"
        >
          <Select
            options={genders}
            name="gender"
            placeholder={txt.sellPageFieldsTxt["Select your gender"]}
            value={genders.find(({ value }) => fields.gender === value)}
            onChange={(value) => handleChange("gender", value.value)}
            classNamePrefix="custom-select"
            className="partizan-open-selling__select"
          />
        </SeasonPassFieldLabel>

        <SeasonPassFieldLabel
          label={txt.sellPageFieldsTxt["Country"]}
          className="partizan-open-selling__fields-label"
          isRequired={checkIsRequired("buyerCountry")}
        >
          <Select
            options={txt.BuySeasonPassModalTxt.companyCountryOptions}
            name="buyerCountry"
            placeholder={txt.sellPageFieldsTxt["Select country"]}
            value={txt.BuySeasonPassModalTxt.companyCountryOptions.find(
                ({ value }) => fields.buyerCountry === value
            )}
            onChange={(value) => handleChange("buyerCountry", value.value)}
            classNamePrefix="custom-select"
            className="partizan-open-selling__select"
        />
        </SeasonPassFieldLabel>

        <SeasonPassFieldLabel
          label={txt.sellPageTxt["Payment method"]}
          isRequired={true}
          className="partizan-open-selling__payment-method"
        >
          <Select
            options={eventData.allowedPaymentProviders.flatMap(p => {
              return p.methods.map(m => {
                return {
                  id: p.providerId,
                  value: paymentMethodOptions[p.providerId],
                  label: m.name,
                }
              })
            })}
            name="paymentMethod"
            value={eventData.allowedPaymentProviders.flatMap(p => {
              return p.methods.map(m => {
                return {
                  id: p.providerId,
                  value: paymentMethodOptions[p.providerId],
                  label: m.name,
                }
              })
            }).find(
              ({ value }) => fields.paymentMethod.id === value.id
            )}
            onChange={(value) => handleChange("paymentMethod", value)}
            classNamePrefix="custom-select"
            className="partizan-open-selling__select"
          />
        </SeasonPassFieldLabel>
      </div>

      <div className="partizan-open-selling__offer-right-block">
        <SelectTickets
          totalFee={totalFee}
          isOffers
          tickets={tickets}
          ticketTemplates={eventData.ticketTemplates}
          pricing={pricing}
          hasSeats={hasSeats}
          workspaceKey={eventData.seatsIoWorkspaceId}
          eventSeatsId={eventData.seatsIoEventId}
          event={eventData}
          loading={loading}
          currency={transactionData.currencyCode}
          onObjectSelected={onObjectSelected}
          onObjectDeselected={onObjectDeselected}
          total={transactionData.paymentAmount}
          setCouponHandler={setCouponHandler}
          coupon={coupon}
        />
        <CheckBox
          checked={checkboxeFields.termsAndConditionsChecked}
          id="terms-and-conditions-checkbox"
          name="terms-and-conditions-checkbox"
          onChange={() => handleChangeCheckbox("termsAndConditionsChecked")}
        >
          <span className="partizan-open-selling__text-medium-small">
            {txt.sellPageTxt["I agree with"]}
            <span
              className="partizan-open-selling__terms-of-service-text"
              onClick={showTermsAndConditionsModal}
            >
              {txt.sellPageTxt["Terms of Service"]}
            </span>
          </span>
        </CheckBox>
      </div>
    </div>
  )
};
