import React, { useEffect, useMemo, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Select from 'react-select';
import DatePicker from 'react-datepicker/es';
import moment from "moment";
import momentTimezone from 'moment-timezone';
import { BarChart, Bar, ResponsiveContainer, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import ReactTable from 'react-table';
import swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { getEventStatistics } from '../../../../../store/actions/eventsActions.js';
import NewLoader from '../../../../../components/NewLoader/NewLoader.js';
import { prepareDataForChart } from './helpers/prepareDataForChart.js';
import Icon from '../../../../../components/UI/Icon/Icon.js';
import { CustomTooltip } from './components/CustomTooltip.jsx';
import { currencies } from '../../../../../constants/currencies.js';

import "./EventStatistics.sass";
import { TicketsInfoModal } from './components/TicketsInfoModal.jsx';
import { getAnalyzedData } from './helpers/getAnalyzedData.js';

const customSelectStyles = {
  control: (base) => ({
    ...base,
    height: '46px',
    'min-height': '46px',
    cursor: 'pointer',
  }),
  placeholder: (base) => ({
    ...base,
    color: '#ABABAB',
  }),
};

const period = [
  { value: 0, label: "All time" },
  { value: 1, label: "Day" },
  { value: 2, label: "Week" },
  { value: 3, label: "Month" },
  { value: 4, label: "Custom" },
];

const paymentOptions = [
  { value: 1, label: "Online" },
  { value: 2, label: "Cash" },
  { value: 3, label: "Pin" },
  { value: 4, label: "Generated" },
  { value: 7, label: "Uploaded" },
  { value: 5, label: "Guest" },
  { value: 8, label: "Seasonal" },
  { value: 9, label: "Invoice" },
];

const MySwal = withReactContent(swal);

export const EventStatistics = ({ getEventStatistics, currentEvent, user }) => {
  const [searchPeriod, setSearchPeriod] = useState(period[0]);
  const [startCalendrDate, setStartCalendarDate] = useState(new Date());
  const [endCalendarDate, setEndCalendarDate] = useState(new Date());
  const [eventData, setEventData] = useState({});

  const [data, setData] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [ticketsInfo, setTicketsInfo] = useState({});
  const [usersInfo, setUsersInfo] = useState({});
  const [chartData, setChartData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [maxTicketsAmount, setMaxTicketsAmount] = useState(0);

  const getData = async () => {
    setLoading(true);

    try {
      const statData = await getEventStatistics(currentEvent.id);

      const timeZone = +momentTimezone().tz(currentEvent.timeZone).format('Z').slice(0, 3) || 0;
      const currency = currencies.find(
          item => item.value === currentEvent.currency
        ).label;

      const dataWithEventTimezone = statData.map((tr) => {
        return {
          ...tr,
          paymentDate: moment(tr.paymentDate).utcOffset(0, true).add("h", timeZone).toISOString(),
        }
      });

      const firstPaymentDate = dataWithEventTimezone.at(-1).paymentDate;
      const lastPaymentDate = dataWithEventTimezone[0].paymentDate;
      const today =
        moment()
        .utc()
        .add("h", timeZone)
        .startOf('day')
        .toISOString();

      const periodStart =
        moment(firstPaymentDate)
        .utc()
        .add("h", timeZone)
        .startOf('day')
        .toISOString();

      const generalEvenData = {
        periodStart,
        periodEnd: lastPaymentDate > today ? today : lastPaymentDate,
        timeZone,
        minDate: firstPaymentDate,
        maxDate: lastPaymentDate > today ? today : lastPaymentDate,
        currency,
      };

      setData(dataWithEventTimezone);
      setEventData(generalEvenData);
      setStartCalendarDate(new Date(generalEvenData.periodStart));
      setEndCalendarDate(new Date(generalEvenData.periodEnd));
      
    } catch {
      setLoading(false);
    }
  };

  const changeSearchPeriodHandler = (item) => {
    setSearchPeriod(item);
  };

  const changeStartDate = (date) => {
    if (moment(date).format() === "Invalid date") return;

    setStartCalendarDate(date);

    const calculatedStartDate =
      moment(date)
      .utc()
      .add("h", eventData.timeZone)
      .startOf('day')
      .toISOString();

    setEventData(prev => ({ ...prev, periodStart: calculatedStartDate }));
  };

  const changeEndDate = (date) => {
    if (moment(date).format() === "Invalid date") return;
    setEndCalendarDate(date);

    const calculatedEndDate =
      moment(date)
      .utc()
      .add("h", eventData.timeZone)
      .endOf('day')
      .toISOString();

    setEventData(prev => ({ ...prev, periodEnd: calculatedEndDate }));
  };

  const changeCustomPeriod = (option) => {
    const value = option === "prev" ? -1 : 1;
    let dateKey = "";
    const { periodStart, periodEnd } = eventData;
    let end = '';

    switch (searchPeriod.value) {
      case 1:
        dateKey = "day";
        end = moment(periodEnd).utc().add(value, dateKey).startOf('day').toISOString();
        break;
      case 2:
        dateKey = "week";
        end = moment(periodStart).utc().add(value, dateKey).startOf('day').endOf("week").toISOString();
        break;
      case 3:
        dateKey = "month";
        end = moment(periodStart).utc().add(value, dateKey).endOf("month").startOf('day').toISOString();
        console.log('end', end);
        console.log('end2', new Date(end));
        
        break;
      
      default: break;
    };

    changeStartDate(new Date(moment(periodStart).add(value, dateKey)));
    changeEndDate(new Date(end));
  };

  const handleChartBarClick = (e) => {
    MySwal.fire({
      title: `${e.date}`,
      html: <TicketsInfoModal
        amount={e.amount}
        ticketsInfo={e.ticketsInfo}
        currency={eventData.currency}
      />,
      customClass: 'swal2-modal',
      width: '1000px',
    })
  };

  const dataPerPeriod = useMemo(() => {
    const { periodStart, periodEnd } = eventData;
    
    return data.filter(tr => {
      return tr.paymentDate >= periodStart && tr.paymentDate <= periodEnd;
    })
  }, [eventData, data]);

  // Determining current dates depending on the selected period
  useEffect(() => {
    const { minDate, maxDate } = eventData;

    switch (searchPeriod.value) {
      case 1: {
        changeStartDate(new Date(moment(maxDate)));
        changeEndDate(new Date(maxDate));
        break;
      }

      case 2: {
        const toWeek = moment(maxDate).set('day', 1)
        changeStartDate(new Date(toWeek));
        changeEndDate(new Date(maxDate));
        break;
      }

      case 3: {
        const toMonth = moment(maxDate).set('date', 1);

        changeStartDate(new Date(toMonth));
        changeEndDate(new Date(maxDate));
        break;
      }

      default:
        changeStartDate(new Date(minDate));
        changeEndDate(new Date(maxDate));
    }
  }, [searchPeriod, data]);

  // Getting analyzed data
  useEffect(() => {
    const { ticketsInfo, usersInfo, tableData } = getAnalyzedData(dataPerPeriod, paymentOptions);

    setTicketsInfo(ticketsInfo);
    setUsersInfo(usersInfo);
    setTableData(tableData);

    const dataForChart = prepareDataForChart({
      dataPerPeriod,
      searchPeriodValue: searchPeriod.value,
      eventData,
    });

    setChartData(dataForChart);

    const maxAmount = Math.max(...dataForChart.map(item => item.amount));
    setMaxTicketsAmount(maxAmount);

    setLoading(false);
  }, [dataPerPeriod]);

  // Getting data from server
  useEffect(() => {
    if (currentEvent.id) {
      getData();
    }
  }, [currentEvent, user.token]);

  const columns = [
    {
      Header: "Ticket name",
      accessor: "ticketName",
      resizable: false
    },
    {
      Header: "Average age",
      Cell: ({ row: { _original: { male, female, undefinedGender, totalAge } } }) => {
        return <>{Math.round(totalAge / (male + female + undefinedGender)) || "N/A"}</>
      },
      resizable: false,
      width: 120,
    },
    {
      Header: "Male",
      accessor: "male",
      resizable: false,
      width: 100,
    },
    {
      Header: "Female",
      accessor: "female",
      resizable: false,
      width: 100,
    },
    {
      Header: "Undefined gender",
      accessor: "undefinedGender",
      resizable: false,
      width: 140,
    },
    {
      Header: "Amount",
      accessor: "ticketsAmount",
      resizable: false,
      width: 100,
    },
    {
      Header: `Paid, ${eventData.currency}`,
      accessor: "totalPaid",
      resizable: false,
      width: 140,
    },
  ];

  if (isLoading) {
    return (
      <NewLoader />
    )
  }

  return (
    <section className='event__statistics statistics'>
      <div className="statistics__header">
        <article className='statistics__period'>
          <div className="add-event__block statistics__select">
            <label>Period</label>
            <Select
              options={period}
              placeholder='Select period'
              name='searchProfilePeriod'
              classNamePrefix='custom-select'
              value={searchPeriod}
              onChange={changeSearchPeriodHandler}
              className='custom-select'
              styles={customSelectStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#6071B5',
                  primary25: '#F2F4FE',
                  primary50: '#F2F4FE',
                  primary75: '#F2F4FE',
                },
              })}
            />
          </div>
          <fieldset className="statistics__custom-period">
            <legend>Select dates</legend>

            <button
              className="btn-primary statistics__prev-button"
              disabled={
                searchPeriod.value === 0 ||
                searchPeriod.value === 4 ||
                eventData.periodStart <= eventData.minDate
              }
              onClick={() => changeCustomPeriod("prev")}
            >
              <Icon name={"chevron"} />
            </button>

            <div className="add-event__block statistics__date-picker">
              <label>Start date</label>

              <DatePicker
                selected={startCalendrDate}
                onChange={(date) => changeStartDate(date)}
                selectsStart
                startDate={startCalendrDate}
                endDate={endCalendarDate}
                minDate={new Date(eventData.minDate)}
                maxDate={endCalendarDate}
                dateFormat="dd.MM.yyyy"
                disabled={searchPeriod.value !== 4}
              />
            </div>
        
            <div className="add-event__block statistics__date-picker">
              <label>End date</label>

              <DatePicker
                selected={endCalendarDate}
                onChange={changeEndDate}
                selectsEnd
                startDate={startCalendrDate}
                endDate={endCalendarDate}
                minDate={startCalendrDate}
                maxDate={new Date(eventData.maxDate)}
                dateFormat="dd.MM.yyyy"
                disabled={searchPeriod.value !== 4}
              />
            </div>

            <button
              className="btn-primary statistics__next-button"
              disabled={
                searchPeriod.value === 0 || 
                searchPeriod.value === 4 ||
                eventData.periodEnd >= eventData.maxDate
              }
              onClick={() => changeCustomPeriod("next")}
            >
              <Icon name={"chevron"} />
            </button>
          </fieldset>
        </article>

        <article className="statistics__info-wrapper">
          <div className="statistics__info">
            <h4 className="statistics__title">Tickets info</h4>
            <ul className="statistics__info-list">
              {paymentOptions.map(pt => {
                return (
                  <li className="statistics__info-item" key={pt.value}>
                    <span>{pt.label}:</span>
                    {ticketsInfo[pt.value]}
                  </li>
                )
              })}
              <li className="statistics__info-item statistics__info-item--total">
                <strong>Total:</strong>
                {ticketsInfo.totalTickets}
              </li>
            </ul>
          </div>
          <div className="statistics__info">
            <h4 className="statistics__title">Online transactions info</h4>
            <ul className="statistics__info-list">
              <li className="statistics__info-item">
                <span>Transactions:</span>
                {usersInfo.totalOnlineTransactions}
              </li>
              <li className="statistics__info-item">
                <span>Unique buyers:</span>
                {usersInfo.totalUniqeBuyers}
              </li>
              <li className="statistics__info-item">
                <span>Average age:</span>
                {usersInfo.averageAge}
              </li>
              <li className="statistics__info-item">
                <span>Male:</span>
                {usersInfo.male}
              </li>
              <li className="statistics__info-item">
                <span>Female:</span>
                {usersInfo.female}
              </li>
              {usersInfo.undefinedGender > 0 && (
                <li className="statistics__info-item">
                  <span>Undefined gender:</span>
                  {usersInfo.undefinedGender}
                </li>
              )}
            </ul>
          </div>
        </article>
      </div>

      <article className="statistics__chart">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            margin={{
              top: 20,
              right: 20,
              left: 20,
              bottom: 40,
            }}
            data={chartData}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="label"
              angle={-45}
              tickMargin={10}
              // interval={0}
              label={{ value: 'Date&Time', position: 'bottom', offset: 15 }}
            />
            <YAxis
              tickCount={maxTicketsAmount < 10 ? maxTicketsAmount + 1 : 10}
              label={{ value: 'Tickets amount', angle: -90, position: 'insideLeft' }}
            />
            <Tooltip
              wrapperStyle={{ background: 'transparent', maxHeight: '280px' }}
              content={<CustomTooltip currency={eventData.currency} />}
              cursor={{ fill: "var(--secondary-light-color)", }}
            />
            <Bar dataKey="amount" fill="var(--secondary-color)" onClick={handleChartBarClick} />
          </BarChart>
        </ResponsiveContainer>
      </article>

      <ReactTable
        data={tableData}
        columns={columns}
        showPagination={false}
        minRows={0}
        sortable={false}
        defaultPageSize={9999999}
      />
    </section>
  )
}

const mapStateToProps = ({ events: { currentEvent }, auth: { user }, }) => ({
  currentEvent,
  user,
});

const mapDispatchToProps = (dispatch) => ({
  getEventStatistics: (eventId) => dispatch(getEventStatistics(eventId))
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(EventStatistics)
);
