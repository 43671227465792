import React, { Fragment } from "react";
import "../EventStatistics.sass";

export const TicketsInfoModal = ({ amount, ticketsInfo, currency, label }) => {
    const totalPaid = ticketsInfo.reduce((acc, value) => acc + value.totalPaid, 0);
    const totalMale = ticketsInfo.reduce((acc, value) => acc + value.male, 0);
    const totalFemale = ticketsInfo.reduce((acc, value) => acc + value.female, 0);
    const totalUndefinedGender = ticketsInfo.reduce((acc, value) => acc + value.undefinedGender, 0);

    return (
      <div
        className={
          `tickets-info-modal ${!totalUndefinedGender ? 'tickets-info-modal--col-5' : ''}`
        }
      >
        <div>
          <strong>Name</strong>
        </div>
        <div>
          <strong>Male</strong>
        </div>
        <div>
          <strong>Female</strong>
        </div>

        {!!totalUndefinedGender && (
          <div>
            <strong>Undefined gender</strong>
          </div>
        )}
        <div>
          <strong>Amount</strong>
        </div>
        <div>
          <strong>Total paid, {currency}</strong>
        </div>

        {ticketsInfo.map(info => (
          <Fragment key={info.name}>
            <div>{info.name}</div>
            <div>{info.male}</div>
            <div>{info.female}</div>
            {!!totalUndefinedGender && <div>{info.undefinedGender}</div>}
            <div>{info.amount}</div>
            <div>{info.totalPaid}</div>
          </Fragment>
        ))}

        {ticketsInfo.length > 1 && (
          <>
            <div>
              <strong>Total</strong>
            </div>
            <div>
              <strong>{totalMale}</strong>
            </div>
            <div>
              <strong>{totalFemale}</strong>
            </div>
            {!!totalUndefinedGender && (
              <div>
                <strong>{totalUndefinedGender}</strong>
              </div>
            )}
            <div>
              <strong>{amount}</strong>
            </div>
            <div>
              <strong>{totalPaid}</strong>
            </div>
          </>
        )}
      </div>
    );
};