export const getAnalyzedData = (dataPerPeriod, paymentOptions) => {
  const paymentCounter = {};
    let totalTickets = 0;
    let averageAge = 0;
    let totalOnlineTransactions = 0;
    let male = 0;
    let female = 0;
    let undefinedGender = 0;
    const tableDataObj = {};

    for (const type of paymentOptions) {
      paymentCounter[type.value] = 0;
    };

    dataPerPeriod.forEach(transaction => {
      if (transaction.paymentType === 1) {
        averageAge += transaction.age;
        totalOnlineTransactions += 1;

        switch (transaction.gender) {
          case "MALE": male += 1;
            break;
          case "FEMALE": female += 1;
            break;

          default: undefinedGender += 1;
        }
      };

      totalTickets += transaction.tickets.length;

      if (transaction.paymentType === 6) {
        paymentCounter["9"] = paymentCounter["9"] + transaction.tickets.length;
      } else {
        paymentCounter[transaction.paymentType] = paymentCounter[transaction.paymentType] + transaction.tickets.length;
      };

      transaction.tickets.forEach(ticket => {
        const key = ticket.ticketTemplateId;

        if (key in tableDataObj) {
          tableDataObj[key] = {
            ...tableDataObj[key],
            ticketsAmount: tableDataObj[key].ticketsAmount + 1,
            totalPaid: tableDataObj[key].totalPaid + ticket.paidPrice,
            totalAge: transaction.paymentType === 1 ? tableDataObj[key].totalAge + transaction.age : tableDataObj[key].totalAge,
            male: transaction.gender === "MALE" && transaction.paymentType === 1 ?
              tableDataObj[key].male + 1 :
              tableDataObj[key].male,
            female: transaction.gender === "FEMALE" && transaction.paymentType === 1 ?
              tableDataObj[key].female + 1 :
              tableDataObj[key].female,
            undefinedGender:
              transaction.gender !== "MALE" && transaction.gender !== "FEMALE" && transaction.paymentType === 1 ?
              tableDataObj[key].undefinedGender + 1 :
              tableDataObj[key].undefinedGender,
          }
        } else {
          tableDataObj[key] = {
            ticketName: ticket.name,
            ticketsAmount: 1,
            totalPaid: ticket.paidPrice,
            totalAge: transaction.paymentType === 1 ? transaction.age : 0,
            male: transaction.gender === "MALE" && transaction.paymentType === 1 ? 1 : 0,
            female: transaction.gender === "FEMALE" && transaction.paymentType === 1 ? 1 : 0,
            undefinedGender: transaction.gender !== "MALE" && transaction.gender !== "FEMALE" && transaction.paymentType === 1 ? 1 : 0,
          }
        }
      });
    });

    const uniqeHash = dataPerPeriod
      .map(tr => tr.buyerHash)
      .filter((value, index, array) => {
        return array.indexOf(value) === array.lastIndexOf(value) && value !== -1;
      });

    const totalUniqeBuyers = uniqeHash.length;
    averageAge = Math.round(averageAge / paymentCounter["1"]) || 0;
    // setLoading(false);

    const ticketsInfo = {
      ...paymentCounter,
      totalTickets,
    };

    const usersInfo = {
      averageAge,
      totalOnlineTransactions,
      totalUniqeBuyers,
      male,
      female,
      undefinedGender,
    };

    const tableData = Object.values(tableDataObj).sort((d1, d2) => d2.ticketsAmount - d1.ticketsAmount);

    return { ticketsInfo, usersInfo, tableData };
};
