import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import queryString from "query-string";
import Select from "react-select";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import CheckBox from "../../../../components/UI/Checkbox/CheckBox";
import TermsAndConditionsModal from "../../TermsAndConditionsModal/TermsAndConditionsModal";

import axios from "../../../../axios-instance";
import Upload from "../../../Upload/Upload";
import Container from "../../../../components/UI/Container/Container";
import Membership2025Adult from "../../../../images/membership2025-adult-new.jpg";
import Membership2025Child from "../../../../images/membership2025-child-new.jpg";
import { IconButton } from "../../../../components/UI/IconButton/IconButton";
import Page404 from "../../../../components/Page404/Page404";
import NewLoader from "../../../../components/NewLoader/NewLoader";
import PleaseWait from "../../../../images/please-wait-season-pass-modal.svg";

import "../SeasonPassPersonalInfo.sass";
import Icon from "../../../../components/UI/Icon/Icon";
import { showErrorModal } from "../../../../modals";
import { ProductPicker } from "../../../OpenSell/TicketsPicker";
import { getCurrencieLabel } from "../../../../constants/currencies";
import { days, month, years } from "../../constants";
import { getCurrentLanguageTexts, setLangCode } from "../../../../constants/seasonPassPersonalInfoLangs";

import { editMembeshipCardInfo, getMemberCards } from "../../../../store/actions/memberActions";
import ls from 'localstorage-slim';
import { getCustomURLs } from "../../../../helpers/getCustomURLs";

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { executeOrderPayment } from "../../../../helpers/paymentHelper/paymentHelper.ts";
import { handleOrderStatus } from "../../../../components/Sells/Modals/statusModals.js";
import { getOc } from "../../../../helpers/optionalChain.ts";

const MySwal = withReactContent(Swal);

const { API_URL } = getCustomURLs();

const memberProductId = 'fd741731-80d1-4580-86e0-a7aa8a710b17';
const memberProduct2Id = '4f159ae6-7b7f-439c-bc5b-e23e46f751d9';

export const MemberInfo = ({ dispatch, membershipList, getMemberCards, editMembeshipCardInfo, ...params }) => {
  const allowMembershipSell = true; // always allow

  const [isLoadingState, setIsLoadingState] = useState(true);
  const [isMemberFound, setIsMemberFound] = useState(false);
  const [isTermsChecked, setIsTermsChecked] = useState(false);

  const blockRef = useRef(null);
  const [blockWidth, setBlockWidth] = useState('300px');

  const [paymentAmount, setPaymentAmount] = useState(0);
  const [currencyCode, setCurrencyCode] = useState("RSD");
  const [selectedMemberships, setSelectedMemberships] = useState([]);
  const [productDefinitions, setProductDefinitions] = useState([]);

  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [streetAndHouseNumber, setStreetAndHouseNumber] = useState("");
  const [city, setCity] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [isPersonalDataChanged, setPersonalDataChanged] = useState(false);

  const [points, setPoints] = useState("");
  const [seasonPassList, setSeasonPassList] = useState([]);
  const [seasonPassListForDelete, setSeasonPassListForDelete] = useState([]);
  const [filesList, setFilesList] = useState([]);
  const [canSave, setCanSave] = useState(true);
  const [isPendingToApprove, setIsPendingToApprove] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [birthday, setBirthday] = useState(new Date());
  const [memberStatus, setMemberStatus] = useState(null);

  const [pickerData, setPickerData] = useState({
    year: null,
    month: null,
    days: null,
  });
  const [defaultLang, setDefaultLang] = useState("en");

  const txt = getCurrentLanguageTexts();

  const handleLang = (lang) => {
    const acceptedLangs = ['en', 'rs'];
    if (!lang || !acceptedLangs.some((key) => key == lang)) {
      setLangCode('rs');
      setDefaultLang("rs");
      return;
    }

    setLangCode(lang);
    setDefaultLang(lang);
  };

  const pageAction = queryString.parse(params.location.search).action;

  const token = ls.get('member-access-token', token);

  const authResult = queryString.parse(params.location.search)["?authResult"];

  const checkActionButtonDisabled = () => {
    return !(birthday
      && firstname
      && lastname
      && phone
      && streetAndHouseNumber
      && city
      && postalCode
      && true);
  };

  const changeInputFirstNameHandler = (e) => {
    setFirstname(e.target.value);
    setPersonalDataChanged(!!e.target.value.trim())
  };

  const changeInputLastNameHandler = (e) => {
    setLastname(e.target.value);
    setPersonalDataChanged(!!e.target.value.trim())
  };

  const changeInputEmailHandler = (e) => {
    setEmail(e.target.value.trim());
  };

  const changeInputPhoneHandler = (phone) => {
    setPhone(phone);
    setPersonalDataChanged(!!phone)
  };

  const changeInputStreetAndHouseNumberHandler = (e) => {
    setStreetAndHouseNumber(e.target.value);
    setPersonalDataChanged(!!e.target.value.trim())
  };

  const changeInputCityHandler = (e) => {
    setCity(e.target.value);
    setPersonalDataChanged(!!e.target.value.trim())
  };

  const changeInputPostalCodeHandler = (e) => {
    setPostalCode(e.target.value);
    setPersonalDataChanged(!!e.target.value.trim())
  };

  const showTermsAndConditionsModal = (ev) => {
    ev.preventDefault();
    MySwal.fire({
      html: <TermsAndConditionsModal />,
      width: "64em",
      customClass: "seasonpass__modal partizan-modal",
    });
  };

  const handleChangeDate = (type, date) => {
    let newPickerData = { ...pickerData, [type]: date };

    setPickerData(newPickerData);

    const isNoEmpty = Object.keys(newPickerData).every(
      (key) => typeof newPickerData[key] === "number"
    );

    const birthday = moment()
      .year(newPickerData.year)
      .month(newPickerData.month)
      .set("D", newPickerData.days)
      .toDate();

    setBirthday(birthday);
    setPersonalDataChanged(!!birthday)
  };

  const showPleaseWaitModal = () => {
    MySwal.fire({
      imageUrl: PleaseWait,
      title: waitModalTxt.title,
      text: waitModalTxt.description,
      allowEscapeKey: false,
      customClass: "partizan-modal seasonpass__modal partizan-info-modal",
      allowOutsideClick: false,
      allowEnterKey: false,
      showConfirmButton: false,
    });
  };

  const setPrice = (products) => {
    setPaymentAmount(
      products.reduce((accumulator, product) => {
        return accumulator + product.price * product.count;
      }, 0)
    );
  };

  const onObjectSelected = (product) => {
    let products = [...selectedMemberships, product];

    let findedMembership = selectedMemberships.find(
      ({ id }) => id === product.id
    );

    if (findedMembership) {
      products = selectedMemberships.map((membership) =>
        membership.id === product.id ? product : membership
      );
    }

    setPrice(products);
    setSelectedMemberships(products);
  };

  const onObjectDeselected = (product) => {
    const products = selectedMemberships.filter(
      (item) => item.id !== product.id
    );

    setPrice(products);
    setSelectedMemberships(products);
  };

  const getEventData = async () => {
    const { data } = await axios.get(`/ProductDefinition/${memberProductId}`);
    const { data: data2 } = await axios.get(`/ProductDefinition/${memberProduct2Id}`);

    setCurrencyCode(
      getCurrencieLabel(data.currencyId)
    );

    if (pageAction === 'selling') {
      data.feeRate = 0;
      data2.feeRate = 0;
    }

    setProductDefinitions([data, data2]);
  };

  const createOrder = async () => {
    await executeOrderPayment({
      firstName: firstname,
      lastName: lastname,
      physicalAddress: streetAndHouseNumber,
      city,
      postalCode,
      phoneNumber: phone,
      buyerEmail: email,
      gender: null,
      paymentMethod: 2,
      type: 2,
      birthday: birthday,

      successUrl: window.location.href.includes('?') ? window.location.href + "&resultOrderId={id}"
        : window.location.href + "?resultOrderId={id}",
      products: selectedMemberships.flatMap((v, i) => {
        const arr = [];

        for (let i = 0; i < v.count; i++) {
          arr.push({
            productDefinitionId: v.id
          })
        }

        return arr;
      })
    });
  };

  const showBuy = () => {
    MySwal.fire({
      title: buyMembershipTxt.buyTitle,
      text: buyMembershipTxt.buyDescription,
      confirmButtonText: buyMembershipTxt.buyBtn,
      cancelButtonText: buyMembershipTxt.cancelBtn,
      showCancelButton: true,
      reverseButtons: true,
      customClass: "partizan-modal delete-user-modal",
    }).then(({ value }) => {
      if (value) {
        handleBuy();
      }
    });
  };

  const handleBuy = async () => {
    try {
      showPleaseWaitModal();
      await createOrder();
    } catch (e) {
      console.log(e);
      showErrorModal({
        text: errorModalTxt.description,
        customClass:
          "seasonpass__modal_info partizan-modal partizan-info-modal",
      });
    }
  };

  const deleteFile = (file, type) => {
    MySwal.fire({
      title: deleteFileTxt.title,
      text: deleteFileTxt.description,
      confirmButtonText: deleteFileTxt.confirmBtn,
      cancelButtonText: deleteFileTxt.cancelBtn,
      showCancelButton: true,
      reverseButtons: true,
      customClass: "partizan-modal delete-user-modal",
    }).then(({ value }) => {
      if (value) {
        if (type === "local") {
          setFilesList((currFiles) => currFiles.filter((f) => f !== file));
        } else {
          setSeasonPassList((currSeasons) =>
            currSeasons.filter((season) => season.id !== file.id)
          );

          setSeasonPassListForDelete((currSeasons) => [...currSeasons, file]);
        }
      }
    });
  };

  const onAddFileHandler = (files, clearAllSelected) => {
    if (files.length > 0) {
      const fileReader = new FileReader();

      fileReader.onloadend = () => {
        setFilesList((currFiles) => [
          ...currFiles,
          { file: files[0], url: fileReader.result },
        ]);

        clearAllSelected();
      };

      fileReader.readAsDataURL(files[0]);
    }
  };

  const loadSeasonPassMember = async () => {
    await axios
      .get(`/SeasonPassMember?accessToken=${token}`)
      .then((response) => {
        if (response.status === 200 && response.data) {

          getOrderData();

          setFirstname(response.data.firstName);
          setLastname(response.data.lastName);
          setEmail(response.data.email);
          setPhone(response.data.phoneNumber);
          setStreetAndHouseNumber(response.data.physicalAddress);
          setCity(response.data.city);
          setPostalCode(response.data.postalCode);
          setPoints(response.data.points);
          setIsPendingToApprove(response.data.status === 2);
          setMemberStatus(response.data.status);

          setPickerData({
            year: moment(response.data.dateOfBirth).year(),
            month: moment(response.data.dateOfBirth).month(),
            days: +moment(response.data.dateOfBirth).format("D"),
          });
          setBirthday(response.data.dateOfBirth);
          //handleLang(response.data.lang);
          if (response.data.status === 3) {
            getEventData();
            setIsApproved(true);
          }
          localStorage.setItem('user_' + response.data.email.toLowerCase().trim(), token);
          setIsMemberFound(true);
          setIsLoadingState(false);

          if (pageAction === 'edit') {
            setIsPendingToApprove(false);
            setIsApproved(false);
          }
          return;
        }

        throw new Error(response.data.errorMessage);
      })
      .catch((err) => {
        setIsLoadingState(false);
        setIsMemberFound(false);
      });

    await axios
      .get(`/Membership/passes?accessToken=${token}&isNew=true`)
      .then((response) => {
        if (response.status === 200 && response.data) {
          const list = response.data.filter(
            (season) => season.requestedToUpdate
          );

          setSeasonPassList(list);
          setIsMemberFound(true);
          setIsLoadingState(false);
          return;
        }

        throw new Error(response.data.errorMessage);
      })
      .catch((err) => {
        setIsLoadingState(false);
        setIsMemberFound(false);
      });
  };

  useEffect(() => {
    if (token) {
      getMemberCards(token, process.env.REACT_APP_MEMBER_EVENT_ID);
    }

    if (pageAction === 'edit') {
      setPersonalDataChanged(true);
    }
  }, [token]);

  const { confirmSaveChanges, handleOrderStatusesTxt, pointsTable, buyMembershipTxt, waitModalTxt, errorModalTxt, deleteFileTxt, dataFormTxt, SeasonPassListTxt } = getCurrentLanguageTexts();

  const showConfirmSaveChangesModal = () => {
    MySwal.fire({
      title: confirmSaveChanges.title,
      // text: confirmSaveChanges.text,
      confirmButtonText: confirmSaveChanges.btnConfirm,
      cancelButtonText: confirmSaveChanges.btnCancel,
      showCancelButton: true,
      reverseButtons: true,
      customClass: "partizan-modal delete-user-modal",
    }).then(({ value }) => {
      if (value) {
        const data = {
          firstName: firstname,
          lastName: lastname,
          phoneNumber: phone,
          physicalAddress: streetAndHouseNumber,
          city: city,
          postalCode: postalCode,
          dateOfBirth: birthday
        };

        if (pageAction !== 'edit') {
          axios
            .put(`/SeasonPassMember?accessToken=${token}&isFinal=${false}`, data)
            .then((response) => {
              if (response.status === 200 && response.data) {

                toast.success(txt.PersonalInfoUpdating.success);
                return;
              }

              throw new Error(response.data.errorMessage);
            })
            .catch((err) => {
              toast.error(txt.PersonalInfoUpdating.error);
            });

          setPersonalDataChanged(false);

          return;
        }

        setIsPendingToApprove(true);
        axios
          .put(`/SeasonPassMember?accessToken=${token}&isFinal=${false}`, data)
          .then((response) => {
            if (response.status === 200 && response.data) {

              if (response.data.result === 2) // Pending to approve
              {
                setIsPendingToApprove(true);
                setIsApproved(false);
              }
              else {// Pending approved
                setIsPendingToApprove(false);
                // setIsApproved(true);
                toast.success(txt.PersonalInfoUpdating.success)
              }
              return;
            }

            throw new Error(response.data.errorMessage);
          })
          .catch((err) => {
            toast.error(txt.PersonalInfoUpdating.error);
            setIsPendingToApprove(false);
          });

        filesList.forEach((value, index, array) => {
          const data = new FormData();
          data.append("file", value.file);

          axios
            .post(
              `/SeasonPassMember/attach-season-pass-photo?accessToken=${token}`,
              data
            )
            .then((response) => {
              if (response.status === 200 && response.data) {
                return;
              }

              throw new Error(response.data.errorMessage);
            })
            .catch((err) => {
              toast.error("Something went wrong");
            });
        });

        seasonPassListForDelete.forEach((season, index, array) => {
          axios
            .delete(
              `/SeasonPassMember/detach-season-pass-photo?seasonPassId=${season.id}&accessToken=${token}`
            )
            .then((response) => {
              if (response.status === 200 && response.data) {
                return;
              }

              throw new Error(response.data.errorMessage);
            })
            .catch((err) => {
              toast.error("Something went wrong");
            });
        });
      }
    });
  };

  const removePayStatus = () => {
    const lang = queryString.parse(params.location.search).lang;

    if (lang) {
      params.history.replace(`${params.match.url}?token=${token}&lang=${defaultLang}`)
    } else {
      params.history.replace(`${params.match.url}?token=${token}`)
    }
  };

  const getOrderData = async () => {
    const urlParams = new URL(window.location.href).searchParams;
    const orderId = urlParams.get('resultOrderId');

    if (!!orderId) {
      const { data } = await axios.get(
        `Order/${orderId}`);

      handleOrderStatus({
        status: data.status,
        orderId,
        buyerEmail: data.info.buyerEmail,
        additionalPaymentStatus: data.externalStatus,
        paymentData: data.paymentDate,
        buyMoreUrl: '',
        authCode: getOc(data, '?.info?.kopaInfo?.authCode', ' '),
        reloadData: getOrderData,
      })
    }
  }

  const getProductDefinitions = () => {
    let products = [];

    products = productDefinitions.filter((p) => p != null).map((product) => {
      if (product.name.toLowerCase().includes("extend")) {
        const numberOfExtendTickens = selectedMemberships.reduce((num, item) => {
          if (
            item.name.toLowerCase().includes("extend") &&
            product.id !== item.id
          ) {
            return num + item.count;
          }
          return num;
        }, 0);

        if (numberOfExtendTickens) {
          return {
            ...product,
            allowedToSell:
              seasonPassList.length - numberOfExtendTickens > 0
                ? seasonPassList.length - numberOfExtendTickens
                : 0,
          };
        }

        return { ...product, allowedToSell: seasonPassList.length };
      }
      return product;
    });

    let count = 0;
    for (let j = 0; j < membershipList.length; j++) {
      if (membershipList[j].ticketName.toLowerCase().includes("extend")) {
        ++count;
      }
    }
    for (let i = 0; i < products.length; i++) {
      if (
        count >= seasonPassList.length &&
        products[i].name.toLowerCase().includes("extend")
      ) {
        delete products[i];
      }
    }

    products.forEach((t, i) => {
      products[i].imgUrl = products[i].id === memberProduct2Id ? Membership2025Child : Membership2025Adult;
    });

    return products;
  };

  useEffect(() => {
    const lang = queryString.parse(params.location.search).lang;

    if (Array.isArray(authResult)) {
      if (lang) {
        params.history.replace(`${params.match.url}?token=${token}&lang=${defaultLang}&?authResult=${authResult[0]}`);
      } else {
        params.history.replace(`${params.match.url}?token=${token}&?authResult=${authResult[0]}`);
      }

      return;
    }
    handleLang(queryString.parse(params.location.search).lang);
    loadSeasonPassMember();
  }, [authResult]);

  useEffect(() => {
    if (blockRef.current) {
      setBlockWidth(
        getComputedStyle(blockRef.current).width
      )
    }
  }, [blockRef.current])

  if (isLoadingState) {
    return <NewLoader />;
  }

  if (!isMemberFound) {
    return <Page404 />;
  }

  return (
    <Container className="seasonpass seasonpass-pers-info">

      <div className="seasonpass-pers-info__logo seasonpass-pers-info__header-logo-wrapper">
        {/* <img src={PartizanOpenSellingLogo} alt="Logo" />
        <h2>SixTix</h2> */}
      </div>
      <div className="seasonpass-pers-info__main-block">
        {isPendingToApprove ? (
          <div className="seasonpass-pers-info__notification">
            <Icon name="clock-white" />
            <h4>{waitModalTxt.waitingForReview}</h4>
          </div>
        ) : null}
        <h2 className="first-title">{dataFormTxt.personalInfoTitle}</h2>
        <div className="seasonpass-pers-info__row">
          <div className="seasonpass-pers-info__column">
            <div className="seasonpass-pers-info__block">
              <label>{dataFormTxt.firstName}</label>
              <input
                type="text"
                name="firstname"
                placeholder=""
                value={firstname}
                onChange={changeInputFirstNameHandler}
              />
            </div>
          </div>

          <div className="seasonpass-pers-info__column">
            <div className="seasonpass-pers-info__block" ref={blockRef}>
              <label>{dataFormTxt.lastName}</label>
              <input
                type="text"
                name="lastname"
                placeholder=""
                value={lastname}
                onChange={changeInputLastNameHandler}
              />
            </div>
          </div>
        </div>
        <div className="seasonpass-pers-info__row">
          <div className="seasonpass-pers-info__column">
            <div className="seasonpass-pers-info__block">
              <label>{dataFormTxt.email}</label>
              <input
                type="text"
                name="email"
                className="email-disable"
                placeholder=""
                value={email}
                onChange={changeInputEmailHandler}
                disabled
              />
            </div>
          </div>
          <div className="seasonpass-pers-info__column">
            <div className="seasonpass-pers-info__block">
              <label>{dataFormTxt.phone}</label>
              <PhoneInput
                inputClass="custom-phone-number-input"
                buttonClass="custom-phone-number-button"
                dropdownClass="custom-phone-number-dropdown"
                searchClass="custom-phone-number-search"
                dropdownStyle={{ width: blockWidth }}
                searchPlaceholder={dataFormTxt.countrySearchPlaceholder}
                // country={'rs'}
                enableSearch={true}
                disableSearchIcon={true}
                value={phone}
                onChange={changeInputPhoneHandler}
              />
            </div>
          </div>
        </div>
        <div className="seasonpass-pers-info__row">
          <div className="seasonpass-pers-info__column">
            <div className="seasonpass-pers-info__block">
              <label>{dataFormTxt.streetAndHouse}</label>
              <input
                type="text"
                name="street-and-house-number"
                placeholder=""
                value={streetAndHouseNumber}
                onChange={changeInputStreetAndHouseNumberHandler}
              />
            </div>
          </div>
          <div className="seasonpass-pers-info__column">
            <div className="seasonpass-pers-info__block">
              <label>{dataFormTxt.city}</label>
              <input
                type="text"
                name="city"
                placeholder=""
                value={city}
                onChange={changeInputCityHandler}
              />
            </div>
          </div>
          <div className="seasonpass-pers-info__column">
            <div className="seasonpass-pers-info__block">
              <label>{dataFormTxt.postalCode}</label>
              <input
                type="text"
                name="postal-code"
                placeholder=""
                value={postalCode}
                onChange={changeInputPostalCodeHandler}
              />
            </div>
          </div>
        </div>
        <div className="seasonpass-pers-info__row">
          <div className="seasonpass-pers-info__column">
            <div className="seasonpass-pers-info__block">
              <label>{dataFormTxt.year}</label>
              <Select
                name="year"
                options={years}
                placeholder=""
                value={years.find(({ value }) => pickerData.year === value)}
                onChange={(value) => handleChangeDate("year", value.value)}
                classNamePrefix="partizan-open-selling__select"
                className="partizan-open-selling__select"
              />
            </div>
          </div>
          <div className="seasonpass-pers-info__column">
            <div className="seasonpass-pers-info__block">
              <label>{dataFormTxt.month}</label>
              <Select
                name="month"
                options={month}
                placeholder=""
                value={month.find(({ value }) => pickerData.month === value)}
                onChange={(value) => handleChangeDate("month", value.value)}
                classNamePrefix="partizan-open-selling__select"
                className="partizan-open-selling__select"
              />
            </div>
          </div>
          <div className="seasonpass-pers-info__column">
            <div className="seasonpass-pers-info__block">
              <label>{dataFormTxt.day}</label>
              <Select
                name="day"
                options={days}
                placeholder=""
                value={days.find(({ value }) => pickerData.days === value)}
                onChange={(value) => handleChangeDate("days", value.value)}
                classNamePrefix="partizan-open-selling__select"
                className="partizan-open-selling__select"
              />
            </div>
          </div>
        </div>
        <button
          className="btn-primary save-changes"
          disabled={!isPersonalDataChanged || checkActionButtonDisabled()}
          onClick={showConfirmSaveChangesModal}
        >
          {dataFormTxt.saveChanges}
        </button>

        {!isApproved && allowMembershipSell && (
          <>
            <h2 className="first-title">{SeasonPassListTxt.spListTitle}</h2>
            <div className="seasonpass-pers-info__list">
              {seasonPassList.map((season) => (
                <div className="upload-img">
                  <img src={season.confirmationPhotoUrl} />
                  {!isPendingToApprove && (
                    <IconButton
                      disabled={!canSave}
                      iconName="trash"
                      className="btn-red IconButton"
                      onClick={() => deleteFile(season, "remote")}
                    />
                  )}
                </div>
              ))}
              {filesList.map((file) => (
                <div className="upload-img">
                  <img src={file.url} />
                  {!isPendingToApprove && (
                    <IconButton
                      disabled={!canSave}
                      iconName="trash"
                      className="btn-red IconButton"
                      onClick={() => deleteFile(file, "local")}
                    />
                  )}
                </div>
              ))}
              {!isPendingToApprove && (
                <>
                  <Upload
                    accept="image/png, image/jpeg, image/*"
                    onButtonEnable={onAddFileHandler}
                  />
                </>
              )}
            </div>
          </>
        )}

        {isApproved && allowMembershipSell && productDefinitions && (
          <>
            <h2 className="first-title">{buyMembershipTxt.buyMembershipsListTitle}</h2>
            <div className="seasonpass-pers-info__tick-list">
              <ProductPicker
                canSelect={
                  selectedMemberships.reduce((num, item) => num + item.count, 0) <
                  4
                }
                onObjectSelected={onObjectSelected}
                onObjectDeselected={onObjectDeselected}
                productDefinitions={getProductDefinitions()}
                currency={currencyCode}
              />
            </div>
            <div className="seasonpass-pers-info__tick-total">
              <span className="">{buyMembershipTxt.total} </span>
              <span className="">
                {paymentAmount} {currencyCode}
              </span>
            </div>
            <CheckBox
              checked={isTermsChecked}
              id="terms-and-conditions-checkbox"
              name="terms-and-conditions-checkbox"
              onChange={() => setIsTermsChecked(!isTermsChecked)}
            >
              <span className="partizan-open-selling__text-medium-small">
                {buyMembershipTxt.iAgreeWith}
                <span
                  className="partizan-open-selling__terms-of-service-text" style={{ color: '#d7b36c' }}
                  onClick={showTermsAndConditionsModal}
                >
                  {buyMembershipTxt.termsOfService}
                </span>
              </span>
            </CheckBox>
            <button
              className="btn-primary save-changes"
              disabled={!paymentAmount || !isTermsChecked}
              onClick={showBuy}
            >
              {buyMembershipTxt.buy}
            </button>
          </>
        )}

        {memberStatus !== 1 && (
          <span className="seasonpass-pers-info__points-total">
            {pointsTable.totalPoints} : {points}
          </span>
        )}
      </div>
    </Container>
  );
};

const mapStateToProps = ({ member }) => ({
  membershipList: member.membershipList,
});
const mapDispatchToProps = (dispatch) => ({
  getMemberCards: (accessToken, eventId) =>
    dispatch(getMemberCards(accessToken, eventId)),
  editMembeshipCardInfo: (accessToken, ticket) =>
    dispatch(editMembeshipCardInfo(accessToken, ticket)),
  dispatch: (foo) => dispatch(foo),
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberInfo)
