import React, { Component } from "react";
import MemberNavigationTabs from "../../../components/NavigationTabs/MemberNavigationTabs";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import ROUTES from "../../../constants/routes";
import MemberInfo from "./Pages/MemberInfo";
import connect from "react-redux/es/connect/connect";
import ls from 'localstorage-slim';
import MemberCards from "./Pages/MemberCards";
import MemberTickets from "./Pages/MemberTickets";

class MemberPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            token: '',
        }
    }

    componentDidMount() {
        const { params } = this.props.match;

        this.setState({ token: params.accessToken });

        ls.set('member-access-token', params.accessToken)
    }

    render() {
        return <>
            <section style={{paddingTop: '40px'}}>
                <div className='new-event__nav'>
                    <MemberNavigationTabs />
                </div>

                <Switch>
                    <Redirect
                        from={ROUTES.MEMBER_NEW}
                        to={ROUTES.MEMBER_NEW_INFO}
                        exact
                    />

                    <Route path={ROUTES.MEMBER_NEW_INFO} exact component={MemberInfo} />
                    <Route path={ROUTES.MEMBER_NEW_CARDS} exact component={MemberCards} />
                    <Route path={ROUTES.MEMBER_NEW_EVENTS} exact component={MemberTickets} />

                </Switch>

            </section>
        </>
    }
}

const mapStateToProps = ({
  auth: { user },
  events: { currentEvent, loading, error, currentTicket },
}) => ({ user, currentEvent, loading, error, currentTicket });

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(MemberPage));