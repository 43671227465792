import React, { Component } from 'react';
import { connect } from 'react-redux';

import StatisticChart from '../StatisticChartOld';
import {
  getAvailableSellDates,
  getColorsMap,
  getNextDaySellData,
  getNextSellTooltipDayData,
  getSellDeviceName,
  getSellTeamName,
  getSellTicketName,
} from '../../../../../../store/reducers/EventDataReducer';
import Icon from '../../../../../../components/UI/Icon/Icon';
import PageTitle from '../../../../../../components/UI/PageTitle/PageTitle';

class SellStatisticForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.id || '',
      selectedDateIndex: 0,
      selectedData: null,
      selectedPayload: null,
      isActive: false,
      activeTab: 'ticket',
    };
  }

  handleChangeDate = (selectedDateIndex) => {
    const { dates } = this.props;
    if (selectedDateIndex < dates.length && selectedDateIndex !== -1) {
      this.setState({
        selectedDateIndex,
        selectedData: null,
        selectedPayload: null,
        isActive: false,
      });
    }
  };

  handleClickOnChart = (data) => {
    if (data) {
      const { selectedDateIndex } = this.state;
      const { dates, nextDayData } = this.props;
      const chartData = nextDayData(dates[selectedDateIndex]);
      this.setState({
        selectedData: chartData,
        selectedPayload: data.activePayload,
        isActive: true,
      });
    }
  };

  handleChangeTab = (activeTabType) => {
    this.setState({
      activeTab: activeTabType,
      selectedData: null,
      selectedPayload: null,
      isActive: false,
    });
  };

  getInitialPayload = (data) => {
    const selectedPayload = [];
    if (data && data.length) {
      const initialTicket = data[0];
      delete initialTicket.time;
      for (const key in initialTicket) {
        selectedPayload.push({
          stroke: this.props.colorMap(this.state.activeTab).get(key),
          strokeWidth: 3,
          r: 0,
          fill: '#fff',
          dataKey: key,
          name: key,
          color: this.props.colorMap(this.state.activeTab).get(key),
          value: initialTicket[key],
          payload: initialTicket,
        });
      }
    }
    return selectedPayload;
  };

  render() {
    const {
      selectedDateIndex,
      selectedData,
      selectedPayload,
      isActive,
      activeTab,
    } = this.state;
    const {
      dates,
      colorMap,
      nextDayData,
      nextDayTooltipData,
      ticketName,
      teamName,
      deviceName,
    } = this.props;
    const chartData = nextDayData(dates[selectedDateIndex]);
    const initialPayloadTicket = this.getInitialPayload(chartData.ticket);
    const initialPayloadTeam = this.getInitialPayload(chartData.team);
    const initialPayloadDevice = this.getInitialPayload(chartData.device);
    const tooltipData = nextDayTooltipData(dates[selectedDateIndex]);
    const prevDate = dates.length > 0 && selectedDateIndex > 0;
    const nextDate = dates.length > 0 && selectedDateIndex < dates.length - 1;
    const getColor = (activeTab, name) => colorMap(activeTab).get(name);
    const isDataNotEmpty = Object.keys(chartData).length !== 0;
    let emptyStateText = 'There are no data to display';

    return (
      <div className='chart-wrapper'>
        <div className='day-picker'>
          <button
            className={`btn-default prev${nextDate ? '' : ' disabled'}`}
            onClick={this.handleChangeDate.bind(this, selectedDateIndex + 1)}
          >
            <Icon name={'chevron'} />
          </button>
          <span className='day-picker-date'>{dates[selectedDateIndex]}</span>
          <button
            className={`btn-default next${prevDate ? '' : ' disabled'}`}
            onClick={this.handleChangeDate.bind(this, selectedDateIndex - 1)}
          >
            <Icon name={'chevron'} />
          </button>
        </div>
        {isDataNotEmpty ? (
          <div className='chart'>
            <StatisticChart
              initialPayloadTicket={initialPayloadTicket}
              initialPayloadTeam={initialPayloadTeam}
              initialPayloadDevice={initialPayloadDevice}
              data={chartData}
              tooltipData={tooltipData}
              color={getColor}
              ticketName={ticketName}
              teamName={teamName}
              deviceName={deviceName}
              handleClickOnChart={this.handleClickOnChart}
              handleChangeTab={this.handleChangeTab}
              selectedData={selectedData}
              isActive={isActive}
              selectedPayload={selectedPayload}
              activeTab={activeTab}
            />
          </div>
        ) : (
          <div className='events__empty-state'>
            <PageTitle>{emptyStateText}</PageTitle>
            <img src='/images/img-empty-events.svg' alt='Empty events' />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    dates: getAvailableSellDates(state),
    ticketName: (key) => getSellTicketName(state, key),
    teamName: (key) => getSellTeamName(state, key),
    deviceName: (key) => getSellDeviceName(state, key),
    nextDayData: (day) => getNextDaySellData(state, day),
    nextDayTooltipData: (day) => getNextSellTooltipDayData(state, day),
    colorMap: (key) => getColorsMap(state).get(key),
    ...ownProps,
  };
};

export default connect(mapStateToProps)(SellStatisticForm);
