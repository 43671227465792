import React, { Fragment } from "react";

export const CustomTooltip = ({ active, payload, currency }) => {
  if (active && payload && payload[0].payload.amount !== 0) {
    const { amount, ticketsInfo } = payload[0].payload;
    const totalPaid = ticketsInfo.reduce((acc, value) => acc + value.totalPaid, 0);

    return (
      <div className="custom-tooltip">
        <div>
          <strong>Name</strong>
        </div>
        <div>
          <strong>Amount</strong>
        </div>
        <div>
          <strong>Total paid, {currency}</strong>
        </div>

        {ticketsInfo.map(info => (
          <Fragment key={info.name}>
            <div>{info.name}</div>
            <div>{info.amount}</div>
            <div>{info.totalPaid}</div>
          </Fragment>
        ))}

        {ticketsInfo.length > 1 && (
          <>
            <div>
              <strong>Total</strong>
            </div>
            <div>
              <strong>{amount}</strong>
            </div>
            <div>
              <strong>{totalPaid}</strong>
            </div>
          </>
        )}
      </div>
    );
  }

  return null;
};