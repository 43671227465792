import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import queryString from "query-string";

import axios from "../../../../axios-instance";
import Container from "../../../../components/UI/Container/Container";
import Page404 from "../../../../components/Page404/Page404";
import NewLoader from "../../../../components/NewLoader/NewLoader";

import "../SeasonPassPersonalInfo.sass";
import ThanksForYourPurchase from "../../../../images/thanks-for-your-purchase.svg";
import Icon from "../../../../components/UI/Icon/Icon";
import { showErrorModal, showInfoModal } from "../../../../modals";
import { getCurrentLanguageTexts, setLangCode } from "../../../../constants/seasonPassPersonalInfoLangs";

import { editMembeshipCardInfo, getMemberCards } from "../../../../store/actions/memberActions";
import { MemberShipTickets } from "../MembershipTickets";
import ls from 'localstorage-slim';
import { getCustomURLs } from "../../../../helpers/getCustomURLs";

import 'react-phone-input-2/lib/style.css';

const { API_URL } = getCustomURLs();

export const MemberTickets = ({ dispatch, membershipList, getMemberCards, editMembeshipCardInfo, ...params }) => {
    const [isLoadingState, setIsLoadingState] = useState(true);
    const [isMemberFound, setIsMemberFound] = useState(false);

    const blockRef = useRef(null);
    const [blockWidth, setBlockWidth] = useState('300px');

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [streetAndHouseNumber, setStreetAndHouseNumber] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");

    const [isPendingToApprove, setIsPendingToApprove] = useState(false);
    const [birthday, setBirthday] = useState(new Date());

    const [defaultLang, setDefaultLang] = useState("en");

    const txt = getCurrentLanguageTexts();

    const handleLang = (lang) => {
        const acceptedLangs = ['en', 'rs'];
        if (!lang || !acceptedLangs.some((key) => key == lang)) {
            setLangCode('rs');
            setDefaultLang("rs");
            return;
        }

        setLangCode(lang);
        setDefaultLang(lang);
    };

    const pageAction = queryString.parse(params.location.search).action;

    const token = ls.get('member-access-token', '');

    const authResult = queryString.parse(params.location.search)["?authResult"];

    const loadSeasonPassMember = async () => {
        await axios
            .get(`/SeasonPassMember?accessToken=${token}`)
            .then((response) => {
                if (response.status === 200 && response.data) {
                    setFirstname(response.data.firstName);
                    setLastname(response.data.lastName);
                    setEmail(response.data.email);
                    handleOrderStatuses({ email: response.data.email });
                    setPhone(response.data.phoneNumber);
                    setStreetAndHouseNumber(response.data.physicalAddress);
                    setCity(response.data.city);
                    setPostalCode(response.data.postalCode);
                    setIsPendingToApprove(response.data.status === 2);

                    setBirthday(response.data.dateOfBirth);

                    localStorage.setItem('user_' + response.data.email.toLowerCase().trim(), token);
                    setIsMemberFound(true);
                    setIsLoadingState(false);

                    if (pageAction == 'edit') {
                        setIsPendingToApprove(false);
                    }
                    return;
                }

                throw new Error(response.data.errorMessage);
            })
            .catch((err) => {
                setIsLoadingState(false);
                setIsMemberFound(false);
            });

        await axios
            .get(`/Membership/passes?accessToken=${token}&isNew=true`)
            .then((response) => {
                if (response.status === 200 && response.data) {

                    setIsMemberFound(true);
                    setIsLoadingState(false);
                    return;
                }

                throw new Error(response.data.errorMessage);
            })
            .catch((err) => {
                setIsLoadingState(false);
                setIsMemberFound(false);
            });
    };

    useEffect(() => {
        if (token) {
            getMemberCards(token, process.env.REACT_APP_MEMBER_EVENT_ID);
        }
    }, [token]);

    const {  handleOrderStatusesTxt, waitModalTxt, } = getCurrentLanguageTexts();

    const removePayStatus = () => {
        const lang = queryString.parse(params.location.search).lang;

        if (lang) {
            params.history.replace(`${params.match.url}?token=${token}&lang=${defaultLang}`)
        } else {
            params.history.replace(`${params.match.url}?token=${token}`)
        }
    };

    const handleOrderStatuses = (data) => {
        switch (authResult) {
            case "CANCELLED":
                break;
            case "AUTHORISED":
                showInfoModal({
                    image: ThanksForYourPurchase,
                    title: handleOrderStatusesTxt.thanksForYourPurchase,
                    allowEscapeKey: true,
                    allowOutsideClick: true,
                    allowEnterKey: true,
                    showConfirmButton: true,
                    confirmButtonText: "Buy More",
                    onConfirm: removePayStatus,
                    text: <>{handleOrderStatusesTxt.thanksText1}<strong>{data.email}</strong>{handleOrderStatusesTxt.thanksText2}</>,
                    // additionalText: `(Payment Status: ${
                    //   data.additionalPaymentStatus
                    // }, Payment Date: ${moment(data.paymentDate).format(
                    //   "DD MMMM YYYY HH:mm"
                    // )}, Transaction Id: ${data.transactionId})`,
                    customClass:
                        "seasonpass__modal_info partizan-modal partizan-info-modal",
                });
                break;
            case "REFUSED":
                showErrorModal({
                    text: handleOrderStatusesTxt.refusedText,
                    showConfirmButton: true,
                    customClass:
                        "seasonpass__modal_info partizan-modal partizan-info-modal",
                    // additionalText: `(Payment Status: ${
                    //   data.additionalPaymentStatus
                    // }, Payment Date: ${moment(data.paymentDate).format(
                    //   "DD MMMM YYYY HH:mm"
                    // )}, Transaction Id: ${data.transactionId})`,
                });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        const lang = queryString.parse(params.location.search).lang;

        if (Array.isArray(authResult)) {
            if (lang) {
                params.history.replace(`${params.match.url}?token=${token}&lang=${defaultLang}&?authResult=${authResult[0]}`);
            } else {
                params.history.replace(`${params.match.url}?token=${token}&?authResult=${authResult[0]}`);
            }

            return;
        }
        handleLang(queryString.parse(params.location.search).lang);
        loadSeasonPassMember();
    }, [authResult]);

    useEffect(() => {
        if (blockRef.current) {
            setBlockWidth(
                getComputedStyle(blockRef.current).width
            )
        }
    }, [blockRef.current])

    if (isLoadingState) {
        return <NewLoader />;
    }

    if (!isMemberFound) {
        return <Page404 />;
    }

    return (
        <Container className="seasonpass seasonpass-pers-info">

            <div className="seasonpass-pers-info__logo seasonpass-pers-info__header-logo-wrapper">
                {/* <img src={PartizanOpenSellingLogo} alt="Logo" />
        <h2>SixTix</h2> */}
            </div>
            <div className="seasonpass-pers-info__main-block">
                {isPendingToApprove ? (
                    <div className="seasonpass-pers-info__notification">
                        <Icon name="clock-white" />
                        <h4>{waitModalTxt.waitingForReview}</h4>
                    </div>
                ) : null}
                

                {membershipList.length > 0
                    && <>
                        <MemberShipTickets memberData={{
                            fullName: firstname + ' ' + lastname,
                            physicalAddress: streetAndHouseNumber,
                            city: city,
                            postalCode: postalCode,
                            phoneNumber: phone,
                            buyerEmail: email,
                            membershipsCount: membershipList.length,
                        }} />
                    </>
                }
            </div>
        </Container>
    );
};

const mapStateToProps = ({ member }) => ({
    membershipList: member.membershipList,
});
const mapDispatchToProps = (dispatch) => ({
    getMemberCards: (accessToken, eventId) =>
        dispatch(getMemberCards(accessToken, eventId)),
    editMembeshipCardInfo: (accessToken, ticket) =>
        dispatch(editMembeshipCardInfo(accessToken, ticket)),
    dispatch: (foo) => dispatch(foo),
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberTickets)
