import moment from "moment";

export const prepareDataForChart = ({
  dataPerPeriod,
  searchPeriodValue,
  eventData
 }) => {
  const { periodStart, periodEnd } = eventData;
  const endMonth = moment(periodStart).endOf('month').get('date');
  const dataForChart = [];
  let N = 0;
  let dateKey = "";

  switch (searchPeriodValue) {
    case 1: N = 24; dateKey = "h";
      break;
    case 2: N = 7; dateKey = "d";
      break;
    case 3: N = endMonth; dateKey = "d";
      break;
    default: {
      const isDay = moment(periodEnd).utc().startOf('day').toISOString() === periodStart;

      if (isDay) {
        N = 24;
        dateKey = "h";
      } else {
        const startDayOfYear =  moment(periodStart).utc().dayOfYear();
        const endDayOfYear =  moment(periodEnd).utc().dayOfYear();
        const daysOfYear = moment(periodStart).utc().endOf("year").dayOfYear();

        if (startDayOfYear < endDayOfYear) {
          N = endDayOfYear - startDayOfYear + 1;
        } else {
          N = daysOfYear - startDayOfYear + endDayOfYear + 1;
        }
        
        dateKey = "d";
      }
    };
  };

  switch (dateKey) {
    case "h":
      for (let i = 0; i <= N; i++) {
        dataForChart.push({
          id: i,
          label: i < 10 ? `0${i}:00` : `${i}:00`,
          amount: 0,
          ticketsInfo: {},
          date: moment(periodEnd).utc().startOf('day').format("DD.MM.YYYY") + ' ' + (i < 10 ? `0${i}:00` : `${i}:00`),
        })
      };
      break;

    case "d":
      for (let i = 0; i < N; i++) {
        const label = moment(periodStart).utc().add(i, "d").format("DD.MM");
        dataForChart.push({
          id: i,
          label,
          amount: 0,
          ticketsInfo: {},
          date: moment(periodStart).utc().add(i, "d").format("DD.MM.YYYY"),
        })
      };
      break;

    case 3: N = endMonth; dateKey = "d";
      break;
    default: N = 31; dateKey = "d";
  }

  dataPerPeriod.forEach(tr => {
    let leftLimit = periodStart;

    for (let i = 0; i <= N; i++) {
      const rightLimit = moment(periodStart).utc().add(i + 1, dateKey).format();

      if (tr.paymentDate >= leftLimit && tr.paymentDate < rightLimit) {
        const existColumnIdx = dataForChart.findIndex(col => col.id === i);

        if (existColumnIdx !== -1) {
          const columnData = dataForChart[existColumnIdx];

          tr.tickets.forEach(ticket => {
            const key = ticket.ticketTemplateId;

            if (key in columnData.ticketsInfo) {
              columnData.ticketsInfo[key] = {
                ...columnData.ticketsInfo[key],
                amount: columnData.ticketsInfo[key].amount + 1,
                totalPaid: columnData.ticketsInfo[key].totalPaid + ticket.paidPrice,
                male: tr.gender === "MALE" && tr.paymentType === 1 ?
                  columnData.ticketsInfo[key].male + 1 :
                  columnData.ticketsInfo[key].male,
                female: tr.gender === "FEMALE" && tr.paymentType === 1 ?
                  columnData.ticketsInfo[key].female + 1 :
                  columnData.ticketsInfo[key].female,
                undefinedGender:
                tr.gender !== "MALE" && tr.gender !== "FEMALE" && tr.paymentType === 1 ?
                  columnData.ticketsInfo[key].undefinedGender + 1 :
                  columnData.ticketsInfo[key].undefinedGender,
              }
            } else {
              columnData.ticketsInfo[key] = {
                name: ticket.name,
                amount: 1,
                totalPaid: ticket.paidPrice,
                male: tr.gender === "MALE" && tr.paymentType === 1 ? 1 : 0,
                female: tr.gender === "FEMALE" && tr.paymentType === 1 ? 1 : 0,
                undefinedGender: tr.gender !== "MALE" && tr.gender !== "FEMALE" && tr.paymentType === 1 ? 1 : 0,
              }
            }
          });

          dataForChart[existColumnIdx] = {
            ...columnData,
            amount: columnData.amount + tr.tickets.length,
          }
        }

        break;
      }

      leftLimit = rightLimit;
    }
  });

  const sortedChartData = dataForChart.map(data => {
    return {
      ...data,
      ticketsInfo: Object.values(data.ticketsInfo).sort((d1, d2) => d2.amount - d1.amount),
    }
  });

  return sortedChartData;
}