/**
     * obj1?.obj2?.obj3 to =>
     * 
     * OptionalChain(obj1,'?.obj2?.obj3')
     * 
     * obj?.func(params) to =>
     * 
     * OptionalChain(obj1,'?.func', () => null)(params)
*/
export function OptionalChain(obj: any, propertyName: string, defaultValue = null): any | null {
    const props = propertyName.split('?.');

    let value = obj;
    for (let i in props) {
        if (!props[i]) {
            continue;
        }

        if (props[i] in value) {
            value = value[props[i]];

            if (!value) {
                value = defaultValue;
                break;
            }
        }
        else {
            value = defaultValue;
            break;
        }
    }

    return value;
}

export const getOc = OptionalChain;