import { toast } from "react-toastify";

import * as actionTypes from '../actionTypes/memberType';
import axios from "../../axios-instance";

const getMemberStart = () => ({
    type: actionTypes.GET_MEMBER_CARDS_START
});

const getMemberSuccess = (data) => ({
    type: actionTypes.GET_MEMBER_CARDS_SUCCESS,
    payload: data,
});

const getMemberFail = (error) => ({
    type: actionTypes.GET_MEMBER_CARDS_FAIL,
    error,
});

export const getMemberCards = (accessToken, eventId) => (dispatch) => {
    dispatch(getMemberStart());

    const isNew = window.location.href.includes('_new');

    return axios
        .get(
            `/Membership/membership?accessToken=${accessToken}&eventId=${eventId}&isNew=${isNew}`
        )
        .then((response) => {
            if (response.status === 200 && response.data) {
                dispatch(getMemberSuccess(response.data));
                return response.data;
            }

            throw new Error(response.data.errorMessage);
        })
        .catch((err) => {
            dispatch(getMemberFail(err));
        })
}

export const editMembeshipCardInfo = (accessToken, payload) => (dispatch) => {
    dispatch({
        type: actionTypes.EDIT_MEMBER_CARD_INFO_SET_LOADING,
        payload: { id: payload.ticketId, state: true}
    })

    const isNew = window.location.href.includes('_new');

    return axios.put(`/Membership/membership?accessToken=${accessToken}&isNew=${isNew}`, payload)
        .then((response) => {
            if (response.status === 200) {
                dispatch({
                    type: actionTypes.EDIT_MEMBER_CARD_INFO,
                    payload: response.data,
                });

                toast.success("Information successfully saved ");
                return response.data;
            }

            throw new Error(response.data.errorMessage);
        })
        .catch((err) => {
            dispatch({
                type: actionTypes.EDIT_MEMBER_CARD_INFO_SET_LOADING,
                payload: { id: payload.ticketId, state: false}
            })
            toast.error("Information not saved");
        });
};