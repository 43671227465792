import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import Container from "../../components/UI/Container/Container";
import PageTitle from "../../components/UI/PageTitle/PageTitle";
import ListEmptyState from "../../components/ScanTeam/ListEmptyState/ListEmptyState";

import ReactTable from "react-table";
import Icon from "../../components/UI/Icon/Icon";
import MobileList from "../../components/ScanTeam/MobileList/MobileList";
import ROUTES from "../../constants/routes";
import {
  deleteScanteam,
  getScanteams,
} from "../../store/actions/scanteamsActions";
import moment from "moment";
import browserTabTitleOverride from "../../helpers/tabTitleOverride";
import NewLoader from "../../components/NewLoader/NewLoader";

import "./ScanTeams.sass";

class ScanTeams extends Component {
  componentDidMount() {
    browserTabTitleOverride();
    const user = JSON.parse(localStorage.getItem("user"));
    this.getScanteams(user.token);
  }

  getScanteams = (userToken) => {
    const { getScanteams } = this.props;

    getScanteams(userToken, 0, 0);
  };

  submitClick = (teamTicketTemplates, e) => {
    if (teamTicketTemplates.length > 0) {
      e.target.parentNode.parentNode.parentNode.classList.toggle("active");
    }
  };

  goToScanteamPage = (id, e) => {
    if (
      e.target.tagName.toLowerCase() !== "svg" &&
      e.target.tagName.toLowerCase() !== "g" &&
      e.target.tagName.toLowerCase() !== "polyline"
    ) {
      this.props.history.push({
        pathname: ROUTES.SCANTEAMS_VIEW.replace(":id", id),
      });
    }
  };

  goToAddScanteam = () => {
    this.props.history.push({
      pathname: ROUTES.SCANTEAMS_ADD,
    });
  };

  render() {
    const { scanteamsList, loading } = this.props.scanteams;
    const emptyList = Object.keys(scanteamsList).length === 0;

    const columns = [
      {
        Header: "Team name",
        accessor: "name",
        width: 180,
      },
      {
        Header: "Tickets",
        Cell: ({ original: { teamEvents, teamTicketTemplates } }) => {
          return teamEvents.map((event) => (
            <div className="scan-teams-list-item" key={event.id}>
              <strong>{event.name}</strong>
              <p>
                {moment(event.startDate).format("DD MMMM HH:mm")} -{" "}
                {moment(event.endDate).format("DD MMMM HH:mm")}
              </p>
              <ul className="scan-teams-list-item__tickets">
                {teamTicketTemplates.map((ticket) => {
                  return (
                    <li key={ticket.id}>
                      {event.id === ticket.eventId ? ticket.ticketName : null}
                    </li>
                  );
                })}
              </ul>
            </div>
          ));
        },
      },
      {
        Header: "Search",
        accessor: "isSearchAvailable",
        Cell: ({ original: { isSearchAvailable } }) => {
          return isSearchAvailable ? "Yes" : "No";
        },
        width: 80,
      },
      {
        Header: "Mark as unscanned",
        accessor: "isUnscanAvailable",
        Cell: ({ original: { isUnscanAvailable } }) => {
          return isUnscanAvailable ? "Yes" : "No";
        },
        width: 120,
      },
      {
        Header: "Sales",
        accessor: "teamSellAbilityType",
        Cell: ({ original: { teamSellAbilityType } }) => {
          return teamSellAbilityType === 2 ? "Yes" : "No";
        },
        width: 60,
      },
      {
        Header: "Scan",
        accessor: "isScanAvailable",
        Cell: ({ original: { isScanAvailable } }) => {
          return isScanAvailable ? "Yes" : "No";
        },
        width: 60,
      },
      {
        Header: "",
        id: "click-me-button",
        Cell: ({ original: { teamTicketTemplates } }) => (
          <button
            className="btn-primary"
            disabled={teamTicketTemplates.length === 0}
            onClick={(e) => {
              this.submitClick(teamTicketTemplates, e);
            }}
          >
            <Icon name={"chevron"} />
          </button>
        ),
        width: 80,
      },
    ];

    return (
      <Container className="scan-teams">
        <div>
          <PageTitle>Teams</PageTitle>
          {loading ? (
            <NewLoader />
          ) : emptyList ? (
            <ListEmptyState />
          ) : (
            <ReactTable
              data={scanteamsList}
              columns={columns}
              showPagination={false}
              minRows={0}
              sortable={false}
              resizable={false}
              getTrProps={(state, rowInfo) => ({
                onClick: (e) => {
                  this.goToScanteamPage(rowInfo.original.id, e);
                },
                style: {
                  alignItems: 'stretch',
                },
              })}
            />
          )}

          <MobileList
            items={scanteamsList}
            goToScanTeamPage={this.goToScanteamPage}
          />
        </div>

        <div className="page-footer">
          <button
            type="button"
            className="btn-primary"
            onClick={this.goToAddScanteam}
          >
            Add scanteam
          </button>
        </div>
      </Container>
    );
  }
}

const mapStateToProps = ({ scanteams }) => ({ scanteams });

const mapDispatchToProps = (dispatch) => ({
  getScanteams: (userToken, skip, take) => {
    dispatch(getScanteams(userToken, skip, take));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ScanTeams)
);
