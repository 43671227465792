import ls from 'localstorage-slim';

const textsOnDifferentLangs = {
  en: {
    generalTxt: {
      cancel: "Cancel",
      save: "Save",
      confirm: "Confirm",
      buy: "Buy",
      agreement: "I agree with",
      terms: "Terms of Service",
      error: "Error",
      somethingWentWrong: "Something Went Wrong",
      tryAgain: "Try Again",
    },
    confirmSaveChanges: {
      title: "Are you sure you want save changes?",
      text: "It’s impossible to take step back then",
      btnConfirm: "Save",
      btnCancel: "Cancel",
    },
    handleOrderStatusesTxt: {
      thanksForYourPurchase: "Thanks for Your Purchase!",
      thanksText1: "We will send all the details to your inbox (",
      thanksText2: ") and look forward to meeting you",
      refusedText: "It seems to have failed to complete the payment, please try again",
    },
    pointsTable: {
      eventNameHeader: "Event Name",
      complexSeatHeader: "Complex Seat",
      pointsHeader: "Points",
      totalPoints: "Total Points",
    },
    sellPageTxt: {
      chooseYourSeatOnMap: "Choose Your Seat On Map",
      tickets: "Tickets",
      "Select a ticket on the map": "Select a ticket on the map",
      "Add a ticket from the list": "Add a ticket from the list",
      "Enter coupon code": "Enter coupon code",
      "Coupon code": "Coupon code",
      "Buy tickets": "Buy tickets",
      "I agree with": "I agree with ",
      "Terms of Service": "Terms of Service",
      "Payment method": "Payment method",
      "Select Tickets": "Select Tickets",
      "Fill in the info": "Fill in the info",
      "Supported by": "Supported by ",
      "Pay": "Pay",
      "Previous": "Previous",
      "Next": "Next",
    },
    sellPageFieldsTxt: {
      "Full Name": "Full Name",
      "Enter your full name": "Enter your full name",
      "Gender": "Gender",
      "Select your gender": "Select your gender",
      "Birth Date": "Birth Date",
      "Year": "Year",
      "Month": "Month",
      "Day": "Day",
      "Street and House Number": "Street and House Number",
      "Enter your street and house number": "Enter your street and house number",
      "City": "City",
      "Enter your city": "Enter your city",
      "Postal Code": "Postal Code",
      "Phone Number": "Phone Number",
      "Enter your phone number": "Enter your phone number",
      "Buyer Email": "Buyer Email",
      "Enter your email": "Enter your email",
      "This is an invalid email": "This is an invalid email",
      "Company Vat Number": "Company Vat Number",
      "Enter company vat number": "Enter company vat number",
      "Company Name": "Company Name",
      "Enter company company name": "Enter company company name",
      "Male": "Male",
      "Female": "Female",
      "Required Field": "Required Field",
      "Select country": "Select country",
      "Country": "Country",
    },
    choosePaymentMethodTxt: {
      "Ticket receipt": "Ticket receipt",
      "You will receive tickets at": "You will receive tickets at",
      "Please repeat your email address": "Please repeat your email address",
      "Enter your email": "Enter your email",
      "This is an invalid email": "This is an invalid email",
      "Use this email": "Use this email",
      "Email Error": "The entered email address does not match the one previously provided.Please enter a correct email address.",
      "Payment method": "Payment method",
    },
    sellPageModals: {
      "Sales not available": "Sales not available",
      "Please wait": "Please wait",
      "You will be redirected to the payment page": "You will be redirected to the payment page",
      "It seems to have failed to complete the payment, please try again": "It seems to have failed to complete the payment, please try again",
      "Thanks for Your Purchase!": "Thanks for Your Purchase!",
      "We will send all the details to your inbox": "We will send all the details to your inbox ",
      " and look forward to meeting you": " and look forward to meeting you",
      "Payment Status: ": "Payment Status: ",
      "Payment Date: ": "Payment Date: ",
      "Transaction Id: ": "Transaction Id: ",
      "Auth Code: ": "Auth Code: ",
      "Your transaction is in processing": "Your transaction is in processing",
      "Transaction was refunded": "Transaction was refunded",
      "Buy More": "Buy More",
    },
    buyMembershipTxt: {
      buyTitle: "Buy Membership",
      buyDescription: "Please make sure you selected the correct types of membership. After payment we will ask you to assign each membercard to Personal data (1 membercard per fan). (This membercard won’t give you any guaranties op seasonpass)",
      buyBtn: "Buy",
      cancelBtn: "Cancel",
      buyMembershipsListTitle: "Buy/Extend Membership",
      total: "Total:",
      iAgreeWith: "I agree with",
      termsOfService: "Terms of Service",
      buy: "Buy",
      saveMessage: "Please save changes to buy membership with your valid personal data",
      saveBtn: "Save",
      emptyDataErrorMessage: "All fields are mandatory",
      individualBuyer: "Individual",
      companyBuyer: "Legal company",
      enterCompanyData: "Enter company data",
    },
    waitModalTxt: {
      title: "Please wait",
      description: "You will be redirected to the payment page",
      waitingForReview: "Waiting for review",
    },
    errorModalTxt: {
      description: "It seems to have failed to complete the payment, please try again"
    },
    deleteFileTxt: {
      title: "Are you sure you delete season pass?",
      description: "It’s impossible to take step back then",
      confirmBtn: "Delete",
      cancelBtn: "Cancel",
    },
    dataFormTxt: {
      buyBtn: "Buy",
      personalInfoTitle: "Personal Information",
      firstName: "First name",
      lastName: "Last name",
      email: "Email address",
      phone: "Phone number",
      streetAndHouse: "Street and House Number",
      city: "City",
      postalCode: "Postal code",
      year: "Year",
      month: "Month",
      day: "Day",
      saveChanges: "Save Changes",
      countrySearchPlaceholder: "Enter country",
      countryNotFound: "No entries to show",
      companyDataTitle: "Fill in company data",
      gender: "Gender",
      male: "Male",
      female: "Female",
    },
    SeasonPassListTxt: {
      title: "Bought Memberships and Season passes",
      spListTitle: "Season Pass List",
      for: "for",
      buySeasonPass: "Buy season pass",
      seasonPassPurchased: "Season pass purchased",
      buyViaInvoice: "Buy all via invoice",
      toolTipResend: "Resend the ticket",
      tooltipPleaseWait: "Please wait"
    },
    memberEventsList: {
      "Future events in": "Future events in",
      "Buy tickets": "Buy tickets",
      "Your season passes": "Your season passes",
      "Your tickets": "Your tickets",
      "Buy more tickets": "Buy more tickets",
      "Show more": "Show more",
      "Your tickets on this event": "Your tickets on this event",
      "Season Pass": "Season Pass",
      "Tickets": "Tickets",
      "Cancelled": "Cancelled",
      resaleBlock: {
        "Send to resale": "Send for resale",
        "Selling": "In selling",
        "Sold": "Sold",
        "Total collected": "Total collected",
        "Seat sent to resale": "Seat sent for resale",
        "Seat was not sent for resale": "Seat was not sent for resale",
      },
      resaleApprovePopup: {
        title: "Are you sure you want to submit ticket for sale?",
        text: "This action is irreversible",
        "Send to resale": "Send for resale",
      },
      tooltipMessage: "You can purchase no more than two tickets in advance per membership card.",
      warning: "Warning",
    },
    EditMembershipCardInfoModalTxt: {
      title: "Edit membership card",
      placeholder: "Enter name",
      selectedSeat: "Selected seat",
      restoreButtonDescription: "Restore selected seat to default",
      success: "NFC added successfully",
      error: "NFC not added"
    },
    BuySeasonPassModalTxt: {
      title: "Buy season pass",
      buyPhysicalNFCCard: "Buy physical NFC card",
      NFCDescription: "Your pass will be in electronic form, if you want to buy a physical NFC card, click here",
      vatPlaceholder: "Company VAT",
      success: "Email with Tickets sent successfully",
      error: "Email with Tickets not sent",
      companyCountryOptions: [
        { label: "Serbia", value: "Serbia" },
        { label: "Other countries", value: "Other countries" },
      ]
    },
    SelectTicketTxt: {
      tickets: "Tickets",
      bookingFee: "Booking fee",
      fixedFeeRate: "Fixed fee rate",
      usedPoints: "Used points",
      total: "Total",
      row: "Row",
      seat: "Seat",
    },
    BuyAllSeasonPassesModalTxt: {
      title: "Buy season passes",
      modalDescription: "You receive email with payment information",
      companyNameLabel: "Company name",
      companyNamePlaceholder: "Enter company name",
      companyVatLabel: "Company VAT number",
      companyVatPlaceholder: "Enter VAT number",
      companyAddressLabel: "Company address",
      companyCityPlaceholder: "Enter city",
      companyAddressPlaceholder: "Enter physical address",
      createInvoiceButton: "Request invoice",
    },
    ResentEmail: {
      success: "Email sent successfully",
      error: "Email not sent",
    },
    PersonalInfoUpdating: {
      success: "Data updated successfully",
      error: "Data not updated",
    }
  },
  rs: {
    generalTxt: {
      cancel: "Otkaži",
      save: "Sačuvaj",
      confirm: "Potvrdi",
      buy: "Kupi",
      agreement: "Saglasan/na sam",
      terms: "Uslovi korišćenja",
      error: "Greška",
      somethingWentWrong: "Nešto je pošlo po zlu",
      tryAgain: "Pokušajte Ponovo",
    },
    confirmSaveChanges: {
      title: "Da li ste sigurni da želite sačuvati izmene?",
      text: "Naknadne izmene neće biti moguće",
      btnConfirm: "Sačuvaj",
      btnCancel: "Otkaži",
    },
    handleOrderStatusesTxt: {
      thanksForYourPurchase: "Uspešna kupovina. Hvala vam!",
      thanksText1: "Poslaćemo vam sve detalje na vašu mail adresu (",
      thanksText2: ") i radujemo se da vas vidimo",
      refusedText: "Kupovina nije uspešna, molimo, pokušajte ponovo",
    },
    pointsTable: {
      eventNameHeader: "Događaj",
      complexSeatHeader: "Sedište",
      pointsHeader: "Poeni",
      totalPoints: "Ukupno poena",
    },
    sellPageTxt: {
      chooseYourSeatOnMap: "Izaberite svoje mesto na mapi",
      tickets: "Ulaznice",
      "Select a ticket on the map": "Izaberite kartu na mapi",
      "Add a ticket from the list": "Dodajte kartu sa liste",
      "Enter coupon code": "Unesite kod kupona",
      "Coupon code": "Kod kupona",
      "Buy tickets": "Kupite karte",
      "I agree with": "Slažem se sa ",
      "Terms of Service": "Uslovi korišćenja usluge",
      "Payment method": "Način plaćanja",
      "Select Tickets": "Izaberite Ulaznice",
      "Fill in the info": "Popunite podatke",
      "Supported by": "Podržano od ",
      "Pay": "Plati",
      "Previous": "Prethodno",
      "Next": "Sledeće",
    },
    sellPageFieldsTxt: {
      "Full Name": "Puno ime",
      "Enter your full name": "Unesite svoje puno ime",
      "Gender": "Pol",
      "Select your gender": "Izaberite svoj pol",
      "Birth Date": "Datum rođenja",
      "Year": "Godina",
      "Month": "Mesec",
      "Day": "Dan",
      "Street and House Number": "Ulica i kućni broj",
      "Enter your street and house number": "Unesite svoju ulicu i kućni broj",
      "City": "Grad",
      "Enter your city": "Unesite svoj grad",
      "Postal Code": "Unesite poštanski broj",
      "Phone Number": "Broj telefona",
      "Enter your phone number": "Unesite svoj broj telefona",
      "Buyer Email": "E-pošta kupca",
      "Enter your email": "Unesite svoju e-poštu",
      "This is an invalid email": "Ovo je nevažeći email",
      "Company Vat Number": "PDV broj kompanije",
      "Enter company vat number": "Unesite PDV broj kompanije",
      "Company Name": "Naziv kompanije",
      "Enter company company name": "Unesite naziv kompanije",
      "Male": "Čoveče",
      "Female": "Žena",
      "Required Field": "Obavezno polje",
      "Select country": "Odaberi državu",
      "Country": "Država",
    },
    choosePaymentMethodTxt: {
      "Ticket receipt": "Potvrda o prijemu karte",
      "You will receive tickets at": "Ulaznice ćete dobiti na",
      "Please repeat your email address": "Ponovite svoju adresu e-pošte",
      "Enter your email": "Unesite svoju e-poštu",
      "This is an invalid email": "Ovo je nevažeći email",
      "Use this email": "Koristite ovu e-poštu",
      "Email Error": "Uneta adresa e-pošte se ne podudara sa prethodno navedenom. Unesite tačnu adresu e-pošte.",
      "Payment method": "Način plaćanja",
    },
    sellPageModals: {
      "Sales not available": "Prodaja nije dostupna",
      "Please wait": "Molimo sačekajte",
      "You will be redirected to the payment page": "Bićete preusmereni na stranicu za plaćanje",
      "It seems to have failed to complete the payment, please try again": "Čini se da plaćanje nije uspelo, pokušajte ponovo",
      "Thanks for Your Purchase!": "Hvala na kupovini!",
      "We will send all the details to your inbox": "Sve detalje ćemo vam poslati u inbok ",
      " and look forward to meeting you": " i radujem se susretu",
      "Payment Status: ": "Status plaćanja: ",
      "Payment Date: ": "Datum plaćanja: ",
      "Transaction Id: ": "ID transakcije: ",
      "Auth Code: ": "Auth Code: ",
      "Your transaction is in processing": "Vaša transakcija je u obradi",
      "Transaction was refunded": "Transakcija je vraćena",
      "Buy More": "Kupite više",
    },
    buyMembershipTxt: {
      buyTitle: "Kupi člansku kartu",
      buyDescription: "Molimo vas da proverite da li ste odabrali odgovarajuće vrste članstva. Nakon plaćanja, zatražićemo od vas da dodelite lične podatke svakoj članskoj karti (jedna članska karta po osobi). (Ukoliko ste vlasnik sezonske ulaznice, kupovinom članske karte imate zagarantovano isto mesto u narednoj sezoni. Ukoliko ne posedujete sezonsku kartu, bićete na prioritetnoj listi za kupovinu nove.)",
      buyBtn: "Kupi",
      cancelBtn: "Otkaži",
      buyMembershipsListTitle: "Kupi člansku kartu 2025",
      total: "Ukupno:",
      iAgreeWith: "Saglasan/na sam",
      termsOfService: "Uslovi korišćenja",
      buy: "Kupi",
      saveMessage: "Spremite promjene kako biste kupili članstvo s važećim osobnim podacima",
      saveBtn: "Uštedjeti",
      emptyDataErrorMessage: "Sva polja su obavezna",
      individualBuyer: "Fizičko lice",
      companyBuyer: "Pravno lice",
      enterCompanyData: "Unesite podatke tvrtke",
    },
    waitModalTxt: {
      title: "Molimo sačekajte",
      description: "Bićete preusmereni na stranicu za plaćanje",
      waitingForReview: "Poslato na reviziju!",
    },
    errorModalTxt: {
      description: "Neuspešna kupovina, molimo pokušajte ponovo"
    },
    deleteFileTxt: {
      title: "Da li ste sigurni da želite da obrišete vašu sezonsku kartu?",
      description: "Nakon ovog koraka, naknadne izmene neće biti moguće",
      confirmBtn: "Obriši",
      cancelBtn: "Otkaži",
    },
    dataFormTxt: {
      buyBtn: "Kupi",
      personalInfoTitle: "Lični podaci",
      firstName: "Ime",
      lastName: "Prezime",
      email: "Email adresa",
      phone: "Kontakt telefon",
      streetAndHouse: "Ulica i broj",
      city: "Grad",
      postalCode: "Poštanski broj",
      year: "Godina rođenja",
      month: "Mesec",
      day: "Dan",
      saveChanges: "Sačuvaj izmene",
      countrySearchPlaceholder: "Unesite državu",
      countryNotFound: "Nema unosa za prikaz",
      companyDataTitle: "Ispunite podatke o kompaniji",
      gender: "Pol",
      male: "Čoveče",
      female: "Žena",
    },
    SeasonPassListTxt: {
      title: "Kupljene članske karte i sezonske propusnice",
      spListTitle: "Sezonske karte",
      for: "za",
      buySeasonPass: "Kupite sezonsku kartu",
      seasonPassPurchased: "Sezonska karta je kupljena",
      buyViaInvoice: "Kupujte sve preko fakture",
      toolTipResend: "Ponovo pošaljite kartu",
      tooltipPleaseWait: "Molim sačekajte"
    },
    memberEventsList: {
      "Future events in": "Budući događaji u",
      "Buy tickets": "Kupite karte",
      "Your season passes": "Vaša sezona prolazi",
      "Your tickets": "Vaše karte",
      "Buy more": "Kupite više",
      "Buy more tickets": "Kupite još karata",
      "Show more": "Pokaži više",
      "Your tickets on this event": "Vaše karte za ovaj događaj",
      "Season Pass": "Sezonska Karta",
      "Tickets": "Ulaznice",
      "Cancelled": "Otkazano",
      resaleBlock: {
        "Send to resale": "Poslati na preprodaju",
        "Selling": "U prodaji",
        "Sold": "Prodato",
        "Total collected": "Ukupno prikupljeno",
        "Seat sent to resale": "Sedište poslato na preprodaju",
        "Seat was not sent for resale": "Sedište nije poslato na preprodaju",
      },
      resaleApprovePopup: {
        title: "Da li ste sigurni da želite da pošaljete kartu na prodaju?",
        text: "Ova akcija je nepovratna",
        "Send to resale": "Poslati na preprodaju",
      },
      tooltipMessage: "Možete kupiti najviše dve karte unapred po jednoj članskoj kartici.",
      warning: "Upozorenje",
    },
    EditMembershipCardInfoModalTxt: {
      title: "Izmeni člansku kartu",
      placeholder: "Unesite ime",
      selectedSeat: "Izabrano sedište",
      restoreButtonDescription: "Vrati izabrano sedište na podrazumevano",
      success: "NFC je uspešno dodan",
      error: "NFC nije dodan"
    },
    BuySeasonPassModalTxt: {
      title: "Kupite sezonsku kartu",
      buyPhysicalNFCCard: "Kupite fizicku NFC karticu",
      NFCDescription: "Vaša propusnica će biti u elektronskom obliku, ukoliko želite da kupite fizičku NFC karticu kliknite ovde",
      vatPlaceholder: "PIB",
      success: "E-pošta s ulaznicama je uspešno poslana",
      error: "E-pošta s ulaznicama nije poslana",
      companyCountryOptions: [
        { label: "Srbija", value: "Srbija" },
        { label: "Druge zemlje", value: "Druge zemlje" },
      ]
    },
    SelectTicketTxt: {
      tickets: "Ulaznice",
      bookingFee: "Troškovi obrade",
      usedPoints: "Iskorišteni bodovi",
      total: "Ukupno",
      row: "Red",
      seat: "Sediste",
    },
    BuyAllSeasonPassesModalTxt: {
      title: "Kupite sezonske karte",
      modalDescription: "Dobijate imejl sa informacijama o plaćanju",
      companyNameLabel: "Naziv kompanije",
      companyNamePlaceholder: "Unesite naziv kompanije",
      companyVatLabel: "Poreski identifikacioni broj PIB (VAT)",
      companyVatPlaceholder: "Unesite PIB broj",
      companyAddressLabel: "Adresa kompanije",
      companyCityPlaceholder: "Unesite grad",
      companyAddressPlaceholder: "Unesite fizičku adresu",
      createInvoiceButton: "Zahtevajte fakturu",
    },
    ResentEmail: {
      success: "E-pošta je uspešno poslana",
      error: "E-pošta nije poslana",
    },
    PersonalInfoUpdating: {
      success: "Podaci su uspešno ažurirani",
      error: "Podaci nisu ažurirani",
    }
  },
};

const allowedUrls = [
  "member?token",
  "events",
  "offers",
]

export const getLangCode = () => {
  for (const url of allowedUrls) {
    if (window.location.href.includes(url)) {
      return ls.get('targetLang') || 'en';
    }
  }

  return 'en';
}

export const setLangCode = (langCode) => {
  ls.set('targetLang', langCode)
}

export function getCurrentLanguageTexts() {
  const lang = getLangCode();

  let txt = textsOnDifferentLangs['en'];

  if (lang !== 'en') {
    txt = textsOnDifferentLangs[lang];
  }

  return txt;
}