import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import queryString from "query-string";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { getEvents } from "../../../../store/actions/eventsActions";

import axios from "../../../../axios-instance";
import Container from "../../../../components/UI/Container/Container";
import Page404 from "../../../../components/Page404/Page404";
import NewLoader from "../../../../components/NewLoader/NewLoader";
import PleaseWait from "../../../../images/please-wait-season-pass-modal.svg";

import "../SeasonPassPersonalInfo.sass";
import ThanksForYourPurchase from "../../../../images/thanks-for-your-purchase.svg";
import Icon from "../../../../components/UI/Icon/Icon";
import { showErrorModal, showInfoModal } from "../../../../modals";
import { paymentMethodOptions, ticketTemplateIds } from "../../constants";
import { getCurrencieLabel } from "../../../../constants/currencies";
import { createMerchantSin } from "../../helpers";
import { getTokenFormPaymentApi } from "../../../../helpers/getTokenFormPaymentApi";
import { getCurrentLanguageTexts, setLangCode } from "../../../../constants/seasonPassPersonalInfoLangs";
import { MembershipCardContainer } from "../MembershipCard";

import { editMembeshipCardInfo, getMemberCards } from "../../../../store/actions/memberActions";
import { BuyAllSeasonPassesModal } from "../Modals/BuyAllSeasonPassesModal";
import ls from 'localstorage-slim';
import { getCustomURLs } from "../../../../helpers/getCustomURLs";

import 'react-phone-input-2/lib/style.css';

const MySwal = withReactContent(Swal);

const { API_URL } = getCustomURLs();

const transactionData = {
    resURL: `${API_URL}/Payment/Webhook/Kopa`,
    sessionValidity: "2023-08-28T14:26:53.000Z",
    redirectURL: window.location.href + "&",
    lang: "en",
    merchantName: "SIXTIX d.o.o",
    iFDirective: process.env.REACT_APP_ALLOWED_URL,
    signedKeys:
        "orderId,paymentAmount,currencyCode,sessionValidity,resURL,redirectURL,lang,merchantName,iFDirective,accessToken",
};

export const MemberCards = ({ dispatch, membershipList, getMemberCards, editMembeshipCardInfo, ...params }) => {
    const [isLoadingState, setIsLoadingState] = useState(true);
    const [isMemberFound, setIsMemberFound] = useState(false);

    const formForSeasonRef = useRef(null);
    const blockRef = useRef(null);
    const [blockWidth, setBlockWidth] = useState('300px');

    const [orderSeasonId, setOrderSeasonId] = useState("");
    const [paymentSeasonAmount, setPaymentSeasonAmount] = useState(0);
    const [currencyCode, setCurrencyCode] = useState("RSD");
    const [seasonEventData, setSeasonEventData] = useState(null);

    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [streetAndHouseNumber, setStreetAndHouseNumber] = useState("");
    const [city, setCity] = useState("");
    const [postalCode, setPostalCode] = useState("");

    const [accessTokenFromPaymentApi, setAccessTokenTokenFromPaymentApi] =
        useState("");

    const [points, setPoints] = useState("");
    const [isPendingToApprove, setIsPendingToApprove] = useState(false);
    const [isApproved, setIsApproved] = useState(false);
    const [birthday, setBirthday] = useState(new Date());

    const [defaultLang, setDefaultLang] = useState("en");

    const txt = getCurrentLanguageTexts();

    const handleLang = (lang) => {
        const acceptedLangs = ['en', 'rs'];
        if (!lang || !acceptedLangs.some((key) => key == lang)) {
            setLangCode('rs');
            setDefaultLang("rs");
            return;
        }

        setLangCode(lang);
        setDefaultLang(lang);
    };

    const pageAction = queryString.parse(params.location.search).action;

    const token = ls.get('member-access-token');

    const authResult = queryString.parse(params.location.search)["?authResult"];

    const openBuyAllModal = async () => {
        const seatsToBuy = membershipList.filter(t => !t.hasSeasonPass && !!t.seat)

        const { data: eventData } = await axios.get(`EventPublicInfo/BySlug/${process.env.REACT_APP_NEW_SEASON_EVENT_SLUG}`);
        const { data } = await axios.post(`TicketTemplate/${process.env.REACT_APP_NEW_SEASON_EVENT_ID}/by-seats`,
            seatsToBuy.map(c =>
                c.area + (!!c.side ? `-${c.side}` : '') + `-${c.row}-${c.seat}`)
        )

        MySwal.fire({
            title: txt.BuyAllSeasonPassesModalTxt['title'],
            html: (
                <BuyAllSeasonPassesModal
                    membershipCards={seatsToBuy}
                    availablePoints={0} // disabled for invoices
                    onConfirmBuySeasonPass={onConfirmBuySeasonPass}
                    event={eventData}
                    selectedTicketTemplates={data}
                />
            ),
            showConfirmButton: false,
            customClass: "public-user-modal",
        });
    };

    const showPleaseWaitModal = () => {
        MySwal.fire({
            imageUrl: PleaseWait,
            title: waitModalTxt.title,
            text: waitModalTxt.description,
            allowEscapeKey: false,
            customClass: "partizan-modal seasonpass__modal partizan-info-modal",
            allowOutsideClick: false,
            allowEnterKey: false,
            showConfirmButton: false,
        });
    };

    const getEventData = async () => {
        const memberEventSlug = process.env.REACT_APP_MEMBER_EVENT_SLUG;
        const { data } = await axios.get(`/EventPublicInfo/BySlug/${memberEventSlug}`);

        const seasonData = await axios.get(`/EventPublicInfo/BySlug/${process.env.REACT_APP_NEW_SEASON_EVENT_SLUG}`);
        const paymentApiRes = await getTokenFormPaymentApi({
            merchantId: data.merchantId,
        });
        if (paymentApiRes) {
            setAccessTokenTokenFromPaymentApi(paymentApiRes.data.access_token);
        }
        setCurrencyCode(
            getCurrencieLabel(data.currencyId)
        );
        if (pageAction === 'selling') {
            seasonData.data.feeRate = 0;
            data.feeRate = 0;
        }

        setSeasonEventData(seasonData.data);
    };

    const createNfcOrderForTheSeatOrder = async (mc) => {
        let paymentType = 2;
        let isOfflineSelling = false;
        let paymentAmount = 3000;
        let user = JSON.parse(localStorage.getItem("user"));
        if (pageAction === 'selling' && user) {
            let result = await getEvents(user.token, false, '', 0, 0);
            if (result) {
                isOfflineSelling = true;
            }
        }

        if (!isOfflineSelling) {
            return;
        }

        const body = {
            transaction: {
                fullName: mc.customerName,
                physicalAddress: streetAndHouseNumber,
                city,
                postalCode,
                phoneNumber: phone,
                buyerEmail: email,
                paymentMethod: paymentMethodOptions[0].value,
                currency: transactionData.currencyCode,
                amount: paymentAmount,
                couponCode: '',
                paymentType: paymentType,
                seats: [
                    {
                        "ticketName": "NFC card",
                        "area": mc.area,
                        "row": mc.row,
                        "seat": mc.seat,
                        "side": mc.side,
                        "ticketTemplateId": process.env.REACT_APP_NEW_SEASON_NFC_CARD_TICKET_TEMPLATE_ID,
                        "amount": 3000,
                        "discount": 0,
                        "fixedDiscount": 0
                    }
                ],
            },
        };

        const { data: response } = await axios.post("Transaction/createForSell", body);

        if (isOfflineSelling) {
            axios
                .post(
                    `/ManageTransactions/ApproveTransaction?transactionId=${response.transactionId}&makeFiscalization=false`,
                    null,
                    {
                        headers: {
                            Authorization: `Bearer ${user.token}`,
                            Accept: "text/plain",
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        //toast.success("Transaction successfully approved");
                        getMemberCards(token, process.env.REACT_APP_MEMBER_EVENT_ID);

                        MySwal.close();
                        toast.success("NFC added successfully")
                        return '';
                    }

                    throw new Error(response.data.errorMessage);
                })
                .catch((err) => {
                    //toast.error("Transaction not approved");
                });
        }
        return paymentType == 1 ? response.transactionId : '';
    }

    const createSeasonPassOrder = async (seats, fullName, paymentAmount, pointToDiscount, { companyName, companyCity, companyAddress, companyVat, companyCountry }) => {
        const onlinePayment = !companyName;
        let paymentType = onlinePayment ? 1 : 6;
        let isOfflineSelling = false;
        let user = JSON.parse(localStorage.getItem("user"));
        if (pageAction === 'selling' && user) {
            let result = await getEvents(user.token, false, '', 0, 0);
            if (result) {
                isOfflineSelling = true;
                paymentType = 2;
            }
        }

        const body = {
            transaction: {
                fullName,
                physicalAddress: streetAndHouseNumber,
                city,
                postalCode,
                phoneNumber: phone,
                buyerEmail: email,
                paymentMethod: paymentMethodOptions[0].value,
                currency: transactionData.currencyCode,
                amount: paymentAmount,
                companyName,
                companyCity,
                companyAddress,
                companyVatNumber: companyVat,
                couponCode: `RESALE_POINTS_${pointToDiscount}`,
                paymentType: paymentType,
                seats,
                companyCountry,
            },
        };

        const { data: response } = await axios.post("Transaction/createForSell", body);
        setPaymentSeasonAmount(paymentAmount);

        if (isOfflineSelling) {
            axios
                .post(
                    `/ManageTransactions/ApproveTransaction?transactionId=${response.transactionId}&makeFiscalization=false`,
                    null,
                    {
                        headers: {
                            Authorization: `Bearer ${user.token}`,
                            Accept: "text/plain",
                        },
                    }
                )
                .then((response) => {
                    if (response.status === 200) {
                        //toast.success("Transaction successfully approved");
                        /* getMemberCards(token, process.env.REACT_APP_MEMBER_EVENT_ID);
                        MySwal.close();
                        toast.success("Invoice sent successfully") */
                        return '';
                    }

                    throw new Error(response.data.errorMessage);
                })
                .catch((err) => {
                    //toast.error("Transaction not approved");
                    console.log('error');
                });
        }
        return paymentType == 1 ? response.transactionId : '';
    }

    const onConfirmBuyNfc = async (mc) => {

        try {
            showPleaseWaitModal();
            const id = await createNfcOrderForTheSeatOrder(mc);

            if (id) {
                setOrderSeasonId(id);

                setTimeout(() => formForSeasonRef.current.submit(), 500);
            }
            else {
                /* MySwal.close();
                toast.success("NFC added successfully") */
            }

        } catch (e) {
            console.log(e);
            showErrorModal({
                text: errorModalTxt.description,
                customClass:
                    "seasonpass__modal_info seasonpass__modal partizan-info-modal",
            });
        }
    }

    const onConfirmBuySeasonPass = async (seats, fullName, paymentAmount, pointToDiscount, { companyName = '', companyCity = '', companyAddress = '', companyVat = '', companyCountry = '' }) => {

        try {
            showPleaseWaitModal();
            const id = await createSeasonPassOrder(seats, fullName, paymentAmount, pointToDiscount, { companyName, companyCity, companyAddress, companyVat, companyCountry });

            if (id) {
                setOrderSeasonId(id);

                setTimeout(() => formForSeasonRef.current.submit(), 500);
            }
            else {
                MySwal.close();
                toast.success(txt.BuySeasonPassModalTxt.success)
            }

        } catch (e) {
            console.log(e);
            showErrorModal({
                text: errorModalTxt.description,
                customClass:
                    "seasonpass__modal_info seasonpass__modal partizan-info-modal",
            });
        }
    }

    const loadSeasonPassMember = async () => {
        await axios
            .get(`/SeasonPassMember?accessToken=${token}`)
            .then((response) => {
                if (response.status === 200 && response.data) {
                    setFirstname(response.data.firstName);
                    setLastname(response.data.lastName);
                    setEmail(response.data.email);
                    handleOrderStatuses({ email: response.data.email });
                    setPhone(response.data.phoneNumber);
                    setStreetAndHouseNumber(response.data.physicalAddress);
                    setCity(response.data.city);
                    setPostalCode(response.data.postalCode);
                    setPoints(response.data.points);
                    setIsPendingToApprove(response.data.status === 2);

                    setBirthday(response.data.dateOfBirth);
                    //handleLang(response.data.lang);
                    if (response.data.status === 3) {
                        getEventData();
                        setIsApproved(true);
                    }
                    localStorage.setItem('user_' + response.data.email.toLowerCase().trim(), token);
                    setIsMemberFound(true);
                    setIsLoadingState(false);

                    if (pageAction == 'edit') {
                        setIsPendingToApprove(false);
                        setIsApproved(false);
                    }
                    return;
                }

                throw new Error(response.data.errorMessage);
            })
            .catch((err) => {
                setIsLoadingState(false);
                setIsMemberFound(false);
            });

        await axios
            .get(`/Membership/passes?accessToken=${token}&isNew=true`)
            .then((response) => {
                if (response.status === 200 && response.data) {
                    setIsMemberFound(true);
                    setIsLoadingState(false);
                    return;
                }

                throw new Error(response.data.errorMessage);
            })
            .catch((err) => {
                setIsLoadingState(false);
                setIsMemberFound(false);
            });
    };

    useEffect(() => {
        if (token) {
            getMemberCards(token, process.env.REACT_APP_MEMBER_EVENT_ID);
        }
    }, [token]);

    const { handleOrderStatusesTxt, waitModalTxt, errorModalTxt, SeasonPassListTxt } = getCurrentLanguageTexts();

    const removePayStatus = () => {
        const lang = queryString.parse(params.location.search).lang;

        if (lang) {
            params.history.replace(`${params.match.url}?token=${token}&lang=${defaultLang}`)
        } else {
            params.history.replace(`${params.match.url}?token=${token}`)
        }
    };

    const handleOrderStatuses = (data) => {
        switch (authResult) {
            case "CANCELLED":
                break;
            case "AUTHORISED":
                showInfoModal({
                    image: ThanksForYourPurchase,
                    title: handleOrderStatusesTxt.thanksForYourPurchase,
                    allowEscapeKey: true,
                    allowOutsideClick: true,
                    allowEnterKey: true,
                    showConfirmButton: true,
                    confirmButtonText: "Buy More",
                    onConfirm: removePayStatus,
                    text: <>{handleOrderStatusesTxt.thanksText1}<strong>{data.email}</strong>{handleOrderStatusesTxt.thanksText2}</>,
                    customClass:
                        "seasonpass__modal_info partizan-modal partizan-info-modal",
                });
                break;
            case "REFUSED":
                showErrorModal({
                    text: handleOrderStatusesTxt.refusedText,
                    showConfirmButton: true,
                    customClass:
                        "seasonpass__modal_info partizan-modal partizan-info-modal",
                });
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        const lang = queryString.parse(params.location.search).lang;

        if (Array.isArray(authResult)) {
            if (lang) {
                params.history.replace(`${params.match.url}?token=${token}&lang=${defaultLang}&?authResult=${authResult[0]}`);
            } else {
                params.history.replace(`${params.match.url}?token=${token}&?authResult=${authResult[0]}`);
            }

            return;
        }
        handleLang(queryString.parse(params.location.search).lang);
        loadSeasonPassMember();
    }, [authResult]);

    useEffect(() => {
        if (blockRef.current) {
            setBlockWidth(
                getComputedStyle(blockRef.current).width
            )
        }
    }, [blockRef.current])

    if (isLoadingState) {
        return <NewLoader />;
    }

    if (!isMemberFound) {
        return <Page404 />;
    }

    const allowInvoices = membershipList.filter(m => !m.hasSeasonPass && m.hasDefaultSeat).length > 0;

    return (
        <Container className="seasonpass seasonpass-pers-info">

            <div className="seasonpass-pers-info__logo seasonpass-pers-info__header-logo-wrapper">
                {/* <img src={PartizanOpenSellingLogo} alt="Logo" />
        <h2>SixTix</h2> */}
            </div>
            <div className="seasonpass-pers-info__main-block">
                {isPendingToApprove ? (
                    <div className="seasonpass-pers-info__notification">
                        <Icon name="clock-white" />
                        <h4>{waitModalTxt.waitingForReview}</h4>
                    </div>
                ) : null}


                {/* SEASON FORM */}
                <form ref={formForSeasonRef}
                    action={process.env.REACT_APP_PAYMENT_LINK}
                    method="post">
                    {Object.keys(transactionData).map((key) => (
                        <input
                            name={key}
                            key={key}
                            type="hidden"
                            value={transactionData[key]}
                        />
                    ))}

                    <input
                        name={"orderId"}
                        key={"orderId"}
                        type="hidden"
                        value={orderSeasonId}
                    />
                    <input
                        name={"paymentAmount"}
                        key={"paymentAmount"}
                        type="hidden"
                        value={paymentSeasonAmount}
                    />

                    <input
                        name={"currencyCode"}
                        key={"currencyCode"}
                        type="hidden"
                        value={currencyCode}
                    />
                    <input
                        name={"accessToken"}
                        key={"accessToken"}
                        type="hidden"
                        value={accessTokenFromPaymentApi}
                    />
                    <input
                        name={"merchantSig"}
                        key={"merchantSig"}
                        type="hidden"
                        value={createMerchantSin(
                            {
                                ...transactionData,
                                orderId: orderSeasonId,
                                paymentAmount: paymentSeasonAmount,
                                currencyCode,
                                accessToken: accessTokenFromPaymentApi,
                            },
                            transactionData.signedKeys
                        )}
                    />

                </form>


                {isApproved && !!membershipList.length && (
                    <>
                        <h2 className="first-title">{SeasonPassListTxt.title}</h2>

                        <MembershipCardContainer
                            onSave={(payload) => editMembeshipCardInfo(token, payload)}
                            membershipCards={membershipList}
                            currencyLabel={currencyCode}
                            onConfirmBuySeasonPass={onConfirmBuySeasonPass}
                            availablePoints={pageAction === 'selling' ? 0 : points}
                            eventInfo={seasonEventData}
                            langCode={defaultLang}
                            onConfirmBuyNfc={onConfirmBuyNfc}
                            ableToBuyNFC={pageAction === 'selling'} />

                        {allowInvoices &&
                            <button className="btn-primary buy-all"
                                onClick={openBuyAllModal}>{txt.SeasonPassListTxt['buyViaInvoice']}</button>
                        }
                    </>
                )}
                
            </div>
        </Container>
    );
};

const mapStateToProps = ({ member }) => ({
    membershipList: member.membershipList,
});
const mapDispatchToProps = (dispatch) => ({
    getMemberCards: (accessToken, eventId) =>
        dispatch(getMemberCards(accessToken, eventId)),
    editMembeshipCardInfo: (accessToken, ticket) =>
        dispatch(editMembeshipCardInfo(accessToken, ticket)),
    dispatch: (foo) => dispatch(foo),
});

export default connect(mapStateToProps, mapDispatchToProps)(MemberCards)
